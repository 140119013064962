import React from "react";
import { RowProps } from "../Row";
import { useControlledVal, useHandleMethodCall } from "../server_hooks";
import { Updatable } from "../useUpdatableProps";
import { Renderable } from "../Renderable";
import { renderValue } from "./renderValue";
import { MaybeLabel, valOrLiteral } from "./MaybeLabel";

type Props = {
  label?: Updatable<Renderable> | Renderable;
  disabled?: boolean;
  appearance?: "checkbox" | "switch";
  autoFocus?: boolean;
} & RowProps;

export function BooleanWidget(props: Props) {
  const { label, disabled, appearance = "checkbox", rowHasLabel } = props;
  const ref = React.useRef<HTMLInputElement>(null);
  const { val, setVal } = useControlledVal();

  useHandleMethodCall("focus", () => {
    ref.current?.focus();
    ref.current?.scrollIntoView();
  });

  // backwards compatibility
  const lblTxt = valOrLiteral(label);

  return (
    <fieldset className="boolean_field">
      <MaybeLabel rowHasLabel={rowHasLabel} />
      <label className="checkbox_label">
        <input
          ref={ref}
          type="checkbox"
          role={appearance === "switch" ? "switch" : undefined}
          disabled={disabled}
          checked={!!val}
          autoFocus={props.autoFocus}
          onChange={(e) => void setVal(e.target.checked)}
        />
        {renderValue(lblTxt)}
      </label>
    </fieldset>
  );
}
