import React, { useState } from "react";
import { Renderable } from "../Renderable";
import { RowProps } from "../Row";
import { useHandleMethodCall, useValidatedVal } from "../server_hooks";
import { Updatable } from "../useUpdatableProps";

import { cx, renderValue } from "./renderValue";
import { ToolTip } from "./ToolTip";

import { Copy, CheckSquare } from "react-feather";
import { valOrLiteral, MaybeLabel } from "./MaybeLabel";
import { ariaInvalid } from "./CheckboxGroupWidget";

type Option = Renderable | { value: string; label: Renderable };

type InputMode = "text" | "tel" | "numeric" | "decimal" | "search";

function valueOf(option: Option): string {
  return (option as any).value ?? option;
}

function labelOf(option: Option): Renderable {
  return (option as any).label ?? option;
}

type Props = {
  id: string;
  label?: string;
  placeholder?: Updatable<string> | string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  pattern?: string;
  patternHint?: string;
  minLength?: number;
  maxLength?: number;
  debounce?: number;
  autocomplete?: Updatable<Option[]>;
  postfix?: "copy_to_clipboard" | string;
  prefix?: string;
  inputMode?: InputMode;
} & RowProps;

export function StringWidget(props: Props) {
  const {
    id,
    label,
    placeholder,
    required,
    readOnly,
    disabled,
    autoFocus,
    pattern,
    patternHint,
    minLength,
    maxLength,
    rowHasLabel,
    autocomplete,
    postfix,
    prefix,
    inputMode,
  } = props;
  const [focus, setFocus] = useState(false);
  const [copied, setCopied] = useState(false);

  const { setVal, val, error, checkErrors, ref } = useValidatedVal<string>();

  useHandleMethodCall("focus", () => {
    ref.current?.focus();
    ref.current?.scrollIntoView();
  });

  const datalist_id = id + "-datalist";

  const datalist = autocomplete?.val ? (
    <datalist id={datalist_id}>
      {autocomplete.val.map((option, i) => (
        <option key={i} value={valueOf(option)}>
          {renderValue(labelOf(option))}
        </option>
      ))}
    </datalist>
  ) : null;

  // TODO: handle icons
  let post;
  if (postfix) {
    if (postfix === "copy_to_clipboard") {
      post = (
        <div
          className="input_postfix svg_icon"
          onClick={(e) => {
            navigator.clipboard.writeText(val || "");
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
          }}
        >
          {copied ? <CheckSquare /> : <Copy />}
        </div>
      );
    } else {
      post = <div className="input_postfix">{postfix}</div>;
    }
  }

  const pre = prefix ? <div className="input_prefix">{prefix}</div> : null;

  const hasConstraints =
    !!required ||
    truthy(pattern) ||
    minLength !== undefined ||
    maxLength !== undefined;

  const input = (
    <input
      className={cx(
        post ? "has_postfix" : undefined,
        pre ? "has_prefix" : undefined
      )}
      aria-invalid={ariaInvalid(error, hasConstraints)}
      type="text"
      dir="auto"
      ref={ref}
      value={val}
      list={datalist ? datalist_id : undefined}
      required={required}
      readOnly={readOnly}
      autoFocus={autoFocus}
      inputMode={inputMode}
      disabled={disabled}
      pattern={pattern}
      minLength={minLength}
      maxLength={maxLength}
      data-patternhint={patternHint}
      placeholder={valOrLiteral(placeholder)}
      onFocus={(e) => setFocus(true)}
      onBlur={(e) => {
        checkErrors();
        setFocus(false);
      }}
      onChange={(e) => setVal(e.target.value, props.debounce)}
    />
  );

  return (
    <label>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <ToolTip message={focus && !!error ? error : undefined}>
        {post || pre ? (
          <div className="addon_container">
            {pre}
            {input}
            {post}
          </div>
        ) : (
          input
        )}
      </ToolTip>
      {datalist}
    </label>
  );
}

export function truthy(x: string | undefined | null) {
  return x != null && x != "";
}
