import React, { useState } from "react";
import { RowProps } from "../Row";
import { useHandleMethodCall, useValidatedVal } from "../server_hooks";
import { Updatable } from "../useUpdatableProps";
import { ToolTip } from "./ToolTip";
import { MaybeLabel, valOrLiteral } from "./MaybeLabel";
import { ariaInvalid } from "./CheckboxGroupWidget";

type Props = {
  label?: string;
  placeholder?: Updatable<string> | string;
  min?: Date;
  max?: Date;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
} & RowProps;

// toLocaleDateString does not match the Date's TZ
function ph(min?: Date, max?: Date) {
  return min != null
    ? max != null
      ? `A date between ${min.toLocaleDateString()} and ${max.toLocaleDateString()}`
      : `A date later than ${min.toLocaleDateString()}`
    : max != null
    ? `A date earlier than ${max.toLocaleDateString()}`
    : undefined;
}

function pad(x: number) {
  return x < 10 ? `0${x}` : x;
}

// d in yyyy-MM-dd for current locale
function yyyymmdd(d: Date) {
  const yyyy = d.getFullYear();
  const MM = pad(d.getMonth() + 1);
  const dd = pad(d.getDate());

  return `${yyyy}-${MM}-${dd}`;
}

export function DateWidget(props: Props) {
  const {
    label,
    min,
    max,
    placeholder,
    required,
    disabled,
    autoFocus,
    readOnly,
    rowHasLabel,
  } = props;
  const [focus, setFocus] = useState(false);
  const { setVal, val, error, checkErrors, ref } = useValidatedVal<"" | Date>();

  useHandleMethodCall("focus", () => {
    ref.current?.focus();
    ref.current?.scrollIntoView();
  });

  const minP = min ? yyyymmdd(min) : undefined;
  const maxP = max ? yyyymmdd(max) : undefined;

  //useEffect(() => {
  //  checkErrors();
  //}, [minP, maxP, required]);

  const hasConstraints = required || min !== undefined || max !== undefined;

  return (
    <label>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <ToolTip message={focus && !!error ? error : undefined}>
        <input
          aria-invalid={ariaInvalid(error, hasConstraints)}
          ref={ref}
          type="date"
          value={val ? yyyymmdd(val) : ""}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
          autoFocus={autoFocus}
          placeholder={valOrLiteral(placeholder) ?? ph(min, max)}
          min={minP}
          max={maxP}
          onFocus={(e) => setFocus(true)}
          onBlur={(e) => {
            checkErrors();
            setFocus(false);
          }}
          onChange={(e) => {
            const date = e.target.valueAsDate;
            if (date) {
              const d = new Date(date);
              // shift from local to UTC
              d.setMinutes(d.getMinutes() + date.getTimezoneOffset());
              setVal(d);
            } else {
              setVal("");
            }
          }}
        />
      </ToolTip>
    </label>
  );
}
