import React, { useRef, useState } from "react";
import { RowProps } from "../Row";
import {
  useHandleMethodCall,
  useSetVal,
  useValidatedVal,
} from "../server_hooks";
import { Updatable } from "../useUpdatableProps";
import { ToolTip } from "./ToolTip";
import { valOrLiteral, MaybeLabel } from "./MaybeLabel";
import { ariaInvalid } from "./CheckboxGroupWidget";

type Props = {
  label?: string;
  placeholder?: Updatable<string> | string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  rows: number;
  debounce?: number;
  submitOnEnter?: boolean;
} & RowProps;

// TODO: add debouce

export function TextAreaWidget(props: Props) {
  const {
    label,
    placeholder,
    required,
    readOnly,
    disabled,
    autoFocus,
    rows = 5,
    rowHasLabel,
    debounce = 500,
    submitOnEnter = false,
  } = props;
  const [focus, setFocus] = useState(false);
  const lastSelection = useRef("");

  const setSelection = useSetVal<string>("selection");
  const { setVal, val, error, checkErrors, ref } = useValidatedVal<string>();

  useHandleMethodCall("focus", () => {
    ref.current?.focus();
    ref.current?.scrollIntoView();
  });

  function handleEnter(e: React.KeyboardEvent) {
    if (e.key !== "Enter" || e.shiftKey) return;
    e.preventDefault();
    const txtarea = e.target as HTMLTextAreaElement;
    if (!txtarea.value.trim()) return;
    if (!txtarea.form) return;
    const btn = txtarea.form.querySelector(
      "button, .button_like"
    ) as HTMLElement;
    if (btn) {
      btn.click();
    } else {
      txtarea.form.requestSubmit();
    }
  }

  return (
    <label>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <ToolTip message={focus && !!error ? error : undefined}>
        <textarea
          aria-invalid={ariaInvalid(error, !!required)}
          dir="auto"
          rows={rows}
          ref={ref}
          value={val}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
          autoFocus={autoFocus}
          placeholder={valOrLiteral(placeholder)}
          onFocus={(e) => setFocus(true)}
          onBlur={(e) => {
            checkErrors();
            setFocus(false);
          }}
          onSelect={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const n = e.target;
            const selection = n.value.substring(
              n.selectionStart,
              n.selectionEnd
            );
            if (lastSelection.current !== selection) {
              lastSelection.current = selection;
              setSelection(selection);
            }
          }}
          onChange={(e) => setVal(e.target.value, debounce)}
          onKeyDown={submitOnEnter ? handleEnter : undefined}
        />
      </ToolTip>
    </label>
  );
}
