import { RowProps } from "../Row";
import { Line } from "react-chartjs-2";
import { ImgSize } from "./ImgSize";
import {
  ChartData,
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { darken } from "color2k";
import { cat_color } from "./Colors";
import React from "react";
import { MaybeLabel } from "./MaybeLabel";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

type LineProps = {
  label?: string;
  caption?: string;
  size?: ImgSize;
  data: ChartData<"line">;
} & RowProps;

export function LineChartWidget({
  data,
  label,
  caption,
  size = "m",
  rowHasLabel,
  inRow,
}: LineProps) {
  const options: any = {
    elements: {
      line: {
        borderWidth: 1, // TODO: we'd like 1px legend border but 2px graph lines
        borderJoinStyle: "round",
      },
      point: {
        radius: inRow ? 1 : 2,
      },
    },

    animation: false,

    interaction: {
      mode: "nearest",
    },
    plugins: {
      legend: {
        labels: {
          boxWidth: 10,
          filter: (legend: { text?: string }) => !!legend.text,
        },
        maxHeight: 24, // prevent wrapped legend height from squeeshing the graph
        position: "bottom",
      },
    },
  };

  if (inRow) {
    options.maintainAspectRatio = false;
  } else {
    options.aspectRatio = 2;
  }

  data = {
    ...data,
    datasets: data.datasets.map((ds, index) => {
      if (ds.backgroundColor || ds.borderColor) return ds;
      const c = cat_color(index);

      return {
        ...ds,
        backgroundColor: c,
        borderColor: darken(c, 0.2),
      };
    }),
  };

  return (
    <figure>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <div className={"ui_chart " + size}>
        <Line data={data} options={options} />
      </div>
      {caption && <figcaption>{caption}</figcaption>}
    </figure>
  );
}
