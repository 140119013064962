import { Renderable } from "../Renderable";
import { RowProps } from "../Row";
import { useControlledVal } from "../server_hooks";
import { cx, renderValue } from "./renderValue";
import React, { useEffect, useRef } from "react";
import { Icon } from "./Icon";

type Option =
  | string
  | {
      value: string;
      label?: Renderable;
      icon?: string;
      disabled?: boolean;
      indent?: number;
    };

type Props = {
  id: string;
  options: Option[];
  appearance: "nav" | "tabs";
} & RowProps;

export function NavWidget({ id, options, appearance = "nav" }: Props) {
  const selected = useRef<HTMLLIElement>(null);
  const val = useControlledVal();

  // bring the option into scroll view
  useEffect(() => {
    const n = selected.current;
    if (!n) return;
    const p = n.closest("figure"); // figure
    if (!p) return;
    const sl = p.scrollLeft;
    const pw = p.clientWidth;
    const offset = n.offsetLeft;
    const nw = n.clientWidth;

    if (offset < sl) {
      p.scrollLeft = offset;
    } else if (offset + nw > sl + pw) {
      p.scrollLeft = offset + nw - pw;
    }
  }, [val.val]);

  let ca = "";
  switch (appearance) {
    case "tabs":
      ca = " tabs";
      break;
  }

  const norm = options.map((option) =>
    typeof option === "string" ? { value: option, label: option } : option
  );

  const large = norm.some((o) => o.label != null) ? undefined : "large_icon";

  return (
    <figure className={cx("nav_widget ", ca)} key={id}>
      <nav>
        <ul>
          {norm.map(({ value, label, disabled = false, icon, indent = 0 }) => {
            const lbl = renderValue(label);
            const isSelected = value === val.val;

            const logo = icon ? (
              <Icon icon={icon} className={large} gap={label} />
            ) : null;

            if (disabled) {
              return (
                <li
                  key={value}
                  className={cx(
                    logo && "with_icon",
                    !!indent && `indent-${indent}`
                  )}
                  aria-disabled
                >
                  {logo}
                  {lbl}
                </li>
              );
            } else if (isSelected) {
              return (
                <li
                  key={value}
                  className={cx(
                    "selected",
                    logo && "with_icon",
                    !!indent && `indent-${indent}`
                  )}
                  ref={selected}
                  aria-selected
                >
                  {logo}
                  {lbl}
                </li>
              );
            } else {
              return (
                <li
                  key={value}
                  className={cx(
                    logo && "with_icon",
                    indent ? `indent-${indent}` : undefined
                  )}
                  role="link"
                  onClick={(e) => val.setVal(value)}
                >
                  {logo}
                  {lbl}
                </li>
              );
            }
          })}
        </ul>
      </nav>
    </figure>
  );
}
