import React, { ReactNode, useState } from "react";
import { isEqualRenderable, Renderable } from "../Renderable";
import { RowProps } from "../Row";
import { useHandleMethodCall, useValidatedVal } from "../server_hooks";
import { Updatable } from "../useUpdatableProps";
import { renderValue } from "./renderValue";
import { ToolTip } from "./ToolTip";
import { MaybeLabel } from "./MaybeLabel";
import { ariaInvalid } from "./CheckboxGroupWidget";
import { has } from "immer/dist/internal";

type Option = Renderable | { value: Renderable; label: Renderable };

type Props = {
  id: string;
  label?: string;
  options: Option[];
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
} & RowProps;

function includes(a: Renderable[], v: Renderable): boolean {
  return a.some((x) => isEqualRenderable(x, v));
}

function valueOf(option: Option): Renderable {
  return (option as any).value ?? option;
}

function labelOf(option: Option): Renderable {
  return (option as any).label ?? option;
}

function opt(i: number): string {
  return "o" + i;
}

export function MultiSelectWidget(props: Props) {
  const { id, label, options, disabled, required, placeholder, rowHasLabel } =
    props;
  const [focus, setFocus] = useState(false);

  const {
    setVal,
    val = [],
    error,
    checkErrors,
    ref,
  } = useValidatedVal<Renderable[]>();

  useHandleMethodCall("focus", () => {
    ref.current?.focus();
    ref.current?.scrollIntoView();
  });

  const selectedOptions: string[] = [];
  // we can't put renderables directly into the DOM
  // so we build a map of string option (opt(i)) -> actual option
  // and use it to look up the actual value when we change the selection
  // while we are iterating through the options, we also build up the selection
  // since it needs to refer to the string options
  const mapped = Object.fromEntries(
    options.map((o, i) => {
      const option = opt(i);
      const v = valueOf(o);
      if (includes(val, v)) {
        selectedOptions.push(option);
      }
      return [option, v];
    })
  );

  return (
    <label key={id}>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <ToolTip message={focus && !!error ? error : undefined}>
        <select
          required={required}
          disabled={disabled}
          autoFocus={props.autoFocus}
          aria-invalid={ariaInvalid(error, !!required)}
          ref={ref}
          onFocus={(e) => setFocus(true)}
          onBlur={(e) => {
            checkErrors();
            setFocus(false);
          }}
          style={{ marginTop: 0 }}
          multiple
          value={selectedOptions}
          onChange={(e) =>
            setVal(
              Array.from(
                e.target.selectedOptions,
                (option) => mapped[option.value]
              )
            )
          }
        >
          {options.map((c, i) => (
            <option key={opt(i)} value={opt(i)}>
              {renderValue(labelOf(c))}
            </option>
          ))}
        </select>
      </ToolTip>
    </label>
  );
}
