import React, { useContext, useMemo } from "react";
import { CloseIcon } from "./CloseIcon";
import { RegionsContext, RegionWidget } from "./widgets/RegionWidget";

type Props = {
  modalId: null | string;
};

export function ModalWrapper({ modalId }: Props) {
  if (!modalId) return null;

  const { regions } = useContext(RegionsContext);
  const region = useMemo(() => regions.get(modalId), [modalId]);

  const handleClose = () => {
    region.updateRegion([], "close", undefined, true, 0);
  };

  return (
    <div className="modal_wrapper">
      <article className="modal">
        <CloseIcon onClick={handleClose} key={modalId} />
        <RegionWidget id={modalId} />
      </article>
    </div>
  );
}
