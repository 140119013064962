import React, { Fragment } from "react";
import { RowProps } from "../Row";
import { useAction } from "../server_hooks";
import { Icon } from "./Icon";
import { ZW_SPACE } from "./renderValue";

type Token = {
  label?: string;
  action?: string;
  icon?: string;
};

type Props = {
  items?: Token[];
} & RowProps;

export function Breadcrumb({ items = [] }: Props) {
  const onAction = useAction<string>("click");

  return (
    <nav className="breadcrumb">
      <ul>
        {items.map(({ label, action, icon }, i) => {
          const logo = icon ? <Icon icon={icon} gap={label} /> : null;
          return (
            <Fragment key={i}>
              {!!i && (
                <li key="slash" className="breadcrumb_sep">
                  /
                </li>
              )}
              <li
                key="crumb"
                className="with_icon"
                role={action ? "link" : undefined}
                onClick={action ? () => onAction(action, action) : undefined}
              >
                {logo}
                {label ?? ZW_SPACE}
              </li>
            </Fragment>
          );
        })}
      </ul>
    </nav>
  );
}
