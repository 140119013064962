import { useControlledVal, useSetVal, useEvent } from "../server_hooks";
import React, { useState, useRef, useEffect } from "react";
import { Updatable } from "../useUpdatableProps";
import { renderValue } from "./renderValue";
import { isEqualRenderable, Renderable } from "../Renderable";
import { serializeData } from "../ConsumerManager";
import { RowProps } from "../Row";
import { MaybeLabel } from "./MaybeLabel";

type Columns = {
  "*"?: { search?: boolean; sort?: boolean };
  [key: string]: {
    search?: boolean;
    sort?: boolean;
    label?: string;
    format?: "pill" | "string"; // TODO
  };
};

type CardSize = "s" | "m" | "l";

type Props = {
  rows: Updatable<object[]>;
  literalData: boolean; // literal data can be sorted / filtered locally
  label?: string;
  loading?: string; // loading message
  empty?: string; // empty message
  search?: string; // search placeholder
  size?: number; // number of rows to render
  idKey?: string;
  multiSelect?: boolean;
  cardSize?: CardSize;
  cover: string;
  content: string[];
  columns: Columns;
} & RowProps;

// return items in a that are not found in b
function remove(a, b) {
  return a.filter((i) => !b.find((j) => i == j));
}

export function GalleryWidget(props: Props) {
  const setSerializedSelection = useSetVal<Uint8Array>("selection");
  const sort = useControlledVal<string>("sort");
  const srch = useControlledVal<string>("search");
  const page = useControlledVal<number>("page");
  const onSelected = useEvent("select");
  const primaryIdRef = useRef<Renderable[]>(undefined);

  const [selected, setSelected] = useState({});
  const {
    literalData,
    empty = "No results",
    loading = "Loading...",
    label,
    search = "Search...",
    multiSelect,
    cardSize = "s",
    idKey,
    rows,
    size = 20, // TODO: this needs to become user selectable
    cover,
    content,
    columns,
    rowHasLabel,
    inRow,
  } = props;

  useEffect(() => {
    const values = [];
    const items = rows.val;
    if (items) {
      Object.values(selected).forEach((row) => {
        const item = items.find((val) =>
          isEqualRenderable(val[_id_key], row[_id_key])
        );
        if (item) {
          values.push(item);
        }
      });
    }

    setSerializedSelection(new Uint8Array(serializeData(values)));

    if (!values.length) {
      primaryIdRef.current = [];
    } else if (
      primaryIdRef.current.length !== values.length ||
      !primaryIdRef.current.every((item, i) =>
        isEqualRenderable(item, values[i][_id_key])
      )
    ) {
      primaryIdRef.current = values.map((v) => v[_id_key]);
      onSelected(true);
    }
  }, [selected, rows.val]);

  function sortFilter(rows = [], sort, search = "", searchable) {
    // console.log({ sort, search, searchable });
    search = search.trim().toLowerCase();
    rows = search
      ? rows.filter((r) =>
          searchable.some((c) => (r[c] + "").toLowerCase().includes(search))
        )
      : rows;

    if (!sort) return rows;

    const [dir, col] = sort.startsWith("-")
      ? [-1, sort.substring(1)]
      : [1, sort];

    return rows.slice().sort((a, b) => {
      if (a[col] < b[col]) return -dir;
      if (a[col] > b[col]) return dir;
      return 0;
    });
  }

  const { title, body } = Array.isArray(content)
    ? { title: content[0], body: content.slice(1) }
    : { title: content, body: [] };

  function colLabel(col) {
    return columns?.[col]?.label ?? col;
  }

  function colIsSortable(col) {
    return columns?.[col]?.sort ?? columns?.["*"]?.sort ?? true;
  }

  function colFormat(col) {
    return columns?.[col]?.format;
  }

  function colIsSearcable(col) {
    return columns?.[col]?.search ?? columns?.["*"]?.search ?? true;
  }

  function updateSelection(n) {
    setSelected(n);
  }

  function updateSort(dirSort) {
    sort.setVal(dirSort);
    page.setVal(0);
  }

  let data, emptyMsg, _id_key, cols, currPage, next, prev;

  if (rows.pending && !rows.val) {
    emptyMsg = <span>{loading}</span>;
    cols = [];
    data = [];
  } else if (!rows.val.length) {
    // TODO: this needs to happen elsewhere.  We need to better define what selection means
    // is it based on what's currently known as rows? currently rendered?
    if (Object.keys(selected).length) {
      updateSelection({});
    }

    emptyMsg = <span>{empty}</span>;
    cols = [];
    data = [];
  } else {
    // handle the case where columns was specified.
    cols = Object.keys(rows.val[0]);

    const searchable = cols.filter((c) => colIsSearcable(c));
    data = literalData
      ? sortFilter(rows.val, sort.val, srch.val, searchable)
      : rows.val;

    // TODO: warn to specify an explict id key
    _id_key = idKey ? idKey : cols[0];

    if (literalData) {
      if (srch && !data.length) {
        emptyMsg = <span>No matches for "{srch.val}"</span>;
      }
    } else {
      const rowIds = data.map((row) => row[_id_key]);
      const toDelete = remove(Object.keys(selected), rowIds);
      if (toDelete.length) {
        toDelete.forEach((id) => delete selected[id]);
        updateSelection({ ...selected });
      }

      currPage = page.val || 0;

      // console.log({ size, len: data.length });

      // TODO: entirely remove next / prev if page = 0 and data.length < size

      next =
        data.length >= size ? (
          <code onClick={(e) => page.setVal(currPage + 1)}>next</code>
        ) : (
          <code style={{ opacity: 0.5 }}>next</code>
        );

      prev =
        currPage > 0 ? (
          <code onClick={(e) => page.setVal(currPage - 1)}>prev</code>
        ) : (
          <code style={{ opacity: 0.5 }}>prev</code>
        );
    }
  }

  // TODO: debounce
  const isSearchable = Object.values(columns).some((c) => c.search);
  const searchField = isSearchable ? (
    <code>
      <input
        style={{
          width: "auto",
          height: "auto",
          padding: 0,
          margin: 0,
          border: 0,
          fontSize: "smaller",
        }}
        placeholder={search}
        value={srch.val ?? ""}
        onChange={(e) => void srch.setVal(e.target.value)}
      />
    </code>
  ) : null;

  const sortable = cols.filter((c) => colIsSortable(c));
  const sortCtrl = sortable.length ? (
    <code>
      <select
        style={{
          width: "auto",
          padding: 0,
          margin: 0,
          border: 0,
          minWidth: inRow ? "5em" : "10em", // move this to CSS to be breakpoint based
          fontSize: "smaller",
        }}
        value={sort.val}
        onChange={(e) => updateSort(e.target.value)}
      >
        {sortable.map((c) => (
          <>
            <option key={c} value={c}>
              {colLabel(c) + "+"}
            </option>
            <option key={c + "-"} value={"-" + c}>
              {colLabel(c) + "-"}
            </option>
          </>
        ))}
      </select>
    </code>
  ) : null;

  // TODO: back to switch record range
  // {currPage * size + 1} ‒ {currPage * size + data.length}

  const ctrls =
    searchField || sortCtrl || next || prev ? (
      <small
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          marginBottom: "0.5em",
        }}
      >
        {searchField}
        <span></span>
        {sortCtrl}
        {prev}{" "}
        {prev || next ? (
          <code style={{ width: "4em", textAlign: "center" }}>
            {currPage + 1}
          </code>
        ) : null}{" "}
        {next}
      </small>
    ) : null;

  return (
    <div
      style={{
        marginBottom: "var(--typography-spacing-vertical)",
      }}
    >
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      {ctrls}
      <div className={"gallery " + `gallery-${cardSize.toLowerCase()}`}>
        {emptyMsg}
        {data.map((row, index) => {
          const key = row[_id_key];
          const isSelected = !!selected[key];
          const coverSrc = row[cover];

          return (
            <div
              className="gallery-card"
              key={index}
              onClick={(e) => {
                if (multiSelect) {
                  isSelected ? delete selected[key] : (selected[key] = row);
                  updateSelection({ ...selected });
                } else {
                  updateSelection(isSelected ? {} : { [key]: row });
                }
              }}
            >
              <input
                className="gallery-card-selector"
                type={multiSelect ? "checkbox" : "radio"}
                checked={isSelected}
                readOnly
              />
              {coverSrc ? (
                <img className="gallery-cover" src={coverSrc} />
              ) : null}
              {title ? (
                <small className="gallery-card-title">{row[title]}</small>
              ) : null}
              {body
                .filter((b) => !!row[b])
                .map((b) => (
                  <p key={b}>{renderValue(row[b], colFormat(b))}</p>
                ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}
