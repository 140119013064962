import React, { ReactNode, useEffect, useState } from "react";
import { isEqualRenderable, Renderable } from "../Renderable";
import { RowProps } from "../Row";
import { useHandleMethodCall, useValidatedVal } from "../server_hooks";
import { Updatable } from "../useUpdatableProps";

import { renderValue } from "./renderValue";
import { ToolTip } from "./ToolTip";
import { MaybeLabel } from "./MaybeLabel";
import { ariaInvalid } from "./CheckboxGroupWidget";

let ID = 100;

type Option = Renderable | { value: Renderable; label: Renderable };

type Props = {
  id: string;
  label?: string;
  options: Option[];
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  layout: "vertical" | "horizontal";
  other?: boolean | string;
} & RowProps;

function valueOf(option: Option): Renderable {
  return (option as any).value ?? option;
}

function labelOf(option: Option): Renderable {
  return (option as any).label ?? option;
}

function isInside(e) {
  if (e.currentTarget.contains(e.relatedTarget)) return true;
  if (e.currentTarget === e.relatedTarget) return true;
  return false;
}

export function RadioGroupWidget(props: Props) {
  const {
    label,
    options,
    required,
    disabled,
    autoFocus,
    other,
    rowHasLabel,
    inRow,
    layout = "vertical",
  } = props;

  const otherLabel = other
    ? typeof other === "boolean"
      ? "Other..."
      : other
    : undefined;

  const [otherIsSelected, setOtherIsSelected] = useState<boolean>(false);
  const [otherVal, setOtherVal] = useState<string>("");
  const [id] = useState(ID++);

  const otherValTrimmed = otherVal.trim();

  const [focus, setFocus] = useState<boolean | undefined>(undefined);

  const { setVal, val, error, checkErrors, ref } = useValidatedVal<
    Renderable | undefined
  >();

  const [mainVal, setMainVal] = useState<Renderable | undefined>(val);

  // focus the other input when otherIsSelected becomes true
  useEffect(() => {
    if (error) checkErrors();
    if (!otherIsSelected) return;
    ref.current?.focus();
  }, [otherIsSelected]);

  useHandleMethodCall("focus", () => {
    ref.current?.focus();
    ref.current?.scrollIntoView();
  });

  useEffect(() => {
    setVal(
      otherIsSelected
        ? otherValTrimmed
          ? otherValTrimmed
          : undefined
        : mainVal
    );
  }, [otherIsSelected, otherValTrimmed, mainVal]);

  // check errors on blur,
  // focus the other field if it is selected
  useEffect(() => {
    if (focus) {
      if (otherIsSelected) ref.current?.focus();
      return;
    }
    // skip first render
    if (focus === undefined) return;
    checkErrors();
  }, [focus]);

  return (
    <fieldset
      className="radio_group"
      key={id}
      tabIndex={0}
      onFocus={(e) => setFocus(true)}
      onBlur={(e) => {
        if (isInside(e)) return;
        setFocus(false);
      }}
    >
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <ToolTip inline message={!otherIsSelected && focus ? error : undefined}>
        <div
          className={
            layout === "horizontal" ? "radio_horizontal" : "radio_vertical"
          }
        >
          {options.map((option, i) => {
            // put the ref and required on the first element, unless other is selected.
            // in that case, they go on the other text input
            const isRef = !otherIsSelected && !i;
            const v = valueOf(option);
            const checked =
              !otherIsSelected && mainVal && isEqualRenderable(mainVal, v);
            return (
              <label key={v.toString()} className="radio_label">
                <input
                  ref={isRef ? ref : undefined}
                  required={isRef ? required : undefined}
                  aria-invalid={ariaInvalid(error, !!required)}
                  autoFocus={autoFocus && i === 0}
                  disabled={disabled}
                  type="radio"
                  name={`radio_group_${id}`}
                  checked={!!checked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setMainVal(v);
                      setOtherIsSelected(false);
                    }
                  }}
                />
                {renderValue(labelOf(option))}
              </label>
            );
          })}
          {other ? (
            <ToolTip message={otherIsSelected && focus ? error : undefined}>
              <label
                className="checkbox_label"
                key="__sm_other"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div>
                  <input
                    type="radio"
                    name={`radio_group_${id}`}
                    disabled={disabled}
                    checked={otherIsSelected}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setOtherIsSelected(true);
                      }
                    }}
                  />
                </div>
                <input
                  style={{ margin: 0 }}
                  type="text"
                  aria-invalid={otherIsSelected && error ? true : undefined}
                  disabled={!otherIsSelected || disabled}
                  value={otherVal}
                  ref={otherIsSelected ? ref : undefined}
                  required
                  pattern=".*\S.*"
                  placeholder={otherLabel}
                  onChange={(e) => setOtherVal(e.target.value)}
                />
              </label>
            </ToolTip>
          ) : null}
        </div>
      </ToolTip>
    </fieldset>
  );
}
