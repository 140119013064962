import { type RowProps } from "../Row";
import { useEvent } from "../server_hooks";
import { type ImgSize } from "./ImgSize";
import React, { useEffect, useRef, useState } from "react";
import { AlertCircle, Maximize, Minimize } from "react-feather";
import { px } from "./renderValue";
import { MaybeLabel } from "./MaybeLabel";

const TRANS =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

type Props = {
  label?: string;
  caption?: string;
  size?: ImgSize | "exact";
  src: string; // TODO: URL?
  width?: number;
  height?: number;
  aspect?: string;
  fullscreen: boolean | "allow";
} & RowProps;

interface ClickEvent {
  x: number;
  y: number;
  xr: number;
  yr: number;
  button: number;
  altKey: boolean;
  metaKey: boolean;
  shiftKey: boolean;
  ctrlKey: boolean;
}

type StateChangeEvent = "loaded" | "error";

export function ImageWidget({
  src,
  label,
  caption,
  size = "m",
  width,
  height,
  aspect = width && height ? `${width}/${height}` : undefined,
  fullscreen = false,
  rowHasLabel,
}: Props) {
  const mediaRef = useRef<HTMLImageElement>(null);
  const onClick = useEvent<ClickEvent>("click");
  const [inFS, setInFS] = useState<boolean>();
  const [loading, setLoading] = useState<"loading" | "error" | "ok">("loading");

  useEffect(() => {
    setLoading("loading");
  }, [src]);

  function handleClick(e: React.MouseEvent<HTMLImageElement, MouseEvent>) {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = Math.floor(e.clientX - rect.left);
    const y = Math.floor(e.clientY - rect.top);
    const xr = x / rect.width;
    const yr = y / rect.height;
    const { button, altKey, metaKey, shiftKey, ctrlKey } = e;
    onClick({
      x,
      y,
      xr,
      yr,
      button,
      altKey,
      metaKey,
      shiftKey,
      ctrlKey,
    });
  }

  function requestFullscreen(node: HTMLImageElement | null) {
    if (!node) return;
    if (node.requestFullscreen) {
      node.requestFullscreen().catch((e) => {
        console.log(e);
      });
    } else {
      setInFS(true);
    }
  }

  useEffect(() => {
    if (fullscreen === true) {
      requestFullscreen(mediaRef.current);
    }
  }, [fullscreen]);

  const maxBtn =
    fullscreen === "allow" ? (
      <code
        onClick={(e) => {
          requestFullscreen(mediaRef.current);
        }}
        className="btn lower_right"
      >
        <Maximize />
      </code>
    ) : null;

  const minBtn = (
    <code
      onClick={(e) => {
        setInFS(false);
      }}
      className="btn lower_right"
    >
      <Minimize />
    </code>
  );

  let img;
  const error = loading === "error";
  const ok = loading === "ok";

  // TODO: aria-busy for no aspect images
  if (aspect) {
    const dims =
      size === "exact" && width && height
        ? { width: px(width), height: px(height) }
        : null;
    img = (
      <div
        style={{ aspectRatio: aspect, ...dims }}
        className={`media_busy_wrapper media_container ${size}`}
      >
        {ok ? null : error ? (
          <div className="busy">
            <AlertCircle />
          </div>
        ) : (
          <div className="busy" aria-busy />
        )}
        <img
          ref={mediaRef}
          src={error ? TRANS : src}
          width={width}
          height={height}
          onLoad={
            error
              ? undefined
              : (e) => {
                  setLoading("ok");
                }
          }
          onError={(e) => {
            setLoading("error");
          }}
          onClick={handleClick}
        />
      </div>
    );
  } else {
    img = (
      <img
        ref={mediaRef}
        src={error ? TRANS : src}
        width={width}
        height={height}
        className={size}
        onLoad={
          error
            ? undefined
            : (e) => {
                setLoading("ok");
              }
        }
        onError={(e) => {
          setLoading("error");
        }}
        onClick={handleClick}
      />
    );
  }

  return (
    <figure>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      {maxBtn ? (
        <div className="img_container">
          {img}
          {maxBtn}
        </div>
      ) : (
        img
      )}

      {caption && <figcaption>{caption}</figcaption>}
      {inFS ? (
        <dialog open>
          <div className="img_container">
            <img
              src={src}
              style={aspect ? { aspectRatio: aspect } : undefined}
              width={width}
              height={height}
              onClick={handleClick}
            />
            {minBtn}
          </div>
        </dialog>
      ) : null}
    </figure>
  );
}
