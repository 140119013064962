import { RowProps } from "../Row";
import React from "react";
import { MaybeLabel } from "./MaybeLabel";

type Props = {
  label?: string;
  max?: number;
  value: number;
} & RowProps;

export function ProgressBarWidget(props: Props) {
  const { label, max, value, rowHasLabel } = props;

  return (
    <label className={label ? undefined : "button_aligned"}>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <progress value={value} max={max} />
    </label>
  );
}
