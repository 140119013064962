import { DeclaredToast } from "../protocol";
import { UpdatableInstance } from "../useUpdatableProps";
import { Draft } from "immer";
import { useImmer } from "../useImmer";
import { useEffect } from "react";

type Props = {
  latestToast: UpdatableInstance<DeclaredToast>;
};

type Toast = DeclaredToast & {
  id: number;
  expires: number;
  ttl: number;
};

type State = Readonly<{
  toasts: Toast[];
}>;

type Action =
  | {
      type: "add";
      toast: DeclaredToast;
    }
  | {
      type: "expire";
      expiry: number;
    };

const INITIAL_STATE: State = {
  toasts: [],
};

let ID = 1;
function toastReducer(state: Draft<State>, action: Action) {
  switch (action.type) {
    case "add": {
      const now = Date.now();
      const delay = Math.max(3000, action.toast.msg.length * 25);
      // TODO: 5000 could come from the notification
      const expires =
        Math.max(now, ...state.toasts.map((t) => t.expires)) + delay;
      const ttl = expires - now;
      const toast: Toast = {
        ...action.toast,
        id: ID++,
        expires,
        ttl,
      };
      state.toasts.push(toast);
      break;
    }
    case "expire": {
      // TODO: don't expire toasts that are sticky or have a progress indicator
      state.toasts = state.toasts.filter((t) => t.expires > action.expiry);
    }
  }
}

export function ToastHub({ latestToast }: Props) {
  const [{ toasts }, dispatch] = useImmer(toastReducer, INITIAL_STATE);

  useEffect(() => {
    // returns the unsubscribe method
    return latestToast.addListener((toast) => {
      toast.val && dispatch({ type: "add", toast: toast.val });
    });
  }, [latestToast]);

  useEffect(() => {
    if (!toasts.length) return;
    const expiry = Math.min(...toasts.map((t) => t.expires || 0));
    // console.log({ toasts, expiry, ttl: expiry - Date.now() });
    const id = setTimeout(
      () => dispatch({ type: "expire", expiry }),
      expiry - Date.now()
    );
    return () => clearTimeout(id);
  }, [toasts]);

  if (!toasts.length) return null;

  return (
    <div className="container toast-container">
      {toasts.map(({ msg, id, ttl }) => {
        const delay = ttl - 100;
        return (
          <article
            key={id}
            className="toast"
            // need !important to work in reduced motion mode
            // and we can't set it from react
            // style={{
            //  animation: `fade-out 0.1s ease ${delay}ms forwards`,
            // }}
          >
            {msg}
          </article>
        );
      })}
    </div>
  );
}
