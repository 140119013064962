import React, { useCallback, useEffect, useRef, useState } from "react";
import { RowProps } from "../Row";
import {
  useEvent,
  useHandleMethodCall,
  useValidatedVal,
} from "../server_hooks";

import { ToolTip } from "./ToolTip";
import { MaybeLabel } from "./MaybeLabel";
import { stop } from "./ComboBoxWidget";
import { Icon } from "./Icon";
import { cx } from "./renderValue";

type Props = {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  appearance?: "default" | "drop_zone";
  accept?: string;
  capture?: "user" | "environment";
  multiple: boolean;
} & RowProps;

type FileItem = {
  content: string;
  name: string;
  type: string;
  size: string;
};

function readFile(file): Promise<FileItem> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) =>
      resolve({
        type: file.type,
        name: file.name,
        size: file.size,
        content: e.target?.result?.toString() || "",
      });
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

function readFiles(files: FileList | File[] | null): Promise<FileItem[]> {
  return Promise.all(Array.from(files || []).map(readFile));
}

function fileFilter(file: File, mime: string) {
  // ".mp3"
  if (mime.startsWith(".")) {
    return file.name.toLowerCase().endsWith(mime);
  }
  // "audio/*"
  const type = file.type.toLowerCase();
  if (mime.endsWith("*")) {
    return `${type.split("/")[0]}/*` === mime;
  }
  // "audio/mp3"
  return type === mime;
}

export function FileUploadWidget(props: Props) {
  const {
    label,
    rowHasLabel,
    placeholder,
    required,
    disabled,
    autoFocus,
    appearance = "default",
    accept = "",
    capture,
    multiple,
  } = props;

  const { setVal, val, error, checkErrors, ref } =
    useValidatedVal<FileItem[]>();

  const [focus, setFocus] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const hiddenRef = useRef<HTMLInputElement>(null);

  useHandleMethodCall("focus", () => {
    ref.current?.focus();
    ref.current?.scrollIntoView();
  });

  const filterFiles = useCallback(
    (list: FileList | null) => {
      if (!list || list.length === 0) return [];
      const all = [...list];

      const mimes = accept
        .toLowerCase()
        .split(",")
        .map((x) => x.trim());

      const good = accept
        ? all.filter((f) => mimes.some((mime) => fileFilter(f, mime)))
        : all;

      return multiple ? good : good.slice(0, 1);
    },
    [multiple, accept]
  );

  if (appearance === "default") {
    return (
      <label>
        <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
        <ToolTip message={focus && !!error ? error : undefined}>
          <input
            type="file"
            ref={ref}
            required={required}
            disabled={disabled}
            autoFocus={autoFocus}
            accept={accept}
            capture={capture}
            multiple={multiple}
            placeholder={placeholder}
            onFocus={(e) => setFocus(true)}
            onBlur={(e) => {
              checkErrors();
              setFocus(false);
            }}
            onChange={(e) => readFiles(e.target.files).then(setVal)}
          />
        </ToolTip>
      </label>
    );
  }

  const message = val?.length ? (
    <div className="with_icon" onClick={stop}>
      <div className="nowrap label_gap">
        {val.length > 1 ? <>{val.length} files</> : val[0].name}
      </div>
      <Icon icon="x" onClick={(e) => setVal([])} />
    </div>
  ) : (
    <div className="with_icon">
      <Icon icon={multiple ? "folder-plus" : "file-plus"} gap />
      <div className="nowrap">
        {placeholder
          ? placeholder
          : multiple
          ? "Drop files here or click to select…"
          : "Drop a file here or click to select…"}
      </div>
    </div>
  );
  return (
    <label>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <ToolTip message={focus && !!error ? error : undefined}>
        <div
          className={cx("drop_zone", isDragOver && "drag_over")}
          onClick={(e) => hiddenRef.current?.click()}
          onDragOver={(e) => {
            e.preventDefault();
            setIsDragOver(true);
          }}
          onDragLeave={(e) => setIsDragOver(false)}
          onDrop={(e) => {
            e.preventDefault();
            setIsDragOver(false);
            const allowed = filterFiles(e.dataTransfer.files);
            const old = val || [];
            // TODO: if accept and allowed.length === 0 -> tell the user which files are allowed
            readFiles(allowed).then((add) => {
              if (multiple) {
                setVal([...old, ...add]);
              } else {
                setVal(add);
              }
            });
            e.dataTransfer.clearData();
          }}
        >
          <div className="nowrap">{message}</div>
          <input
            key="validate"
            type="text"
            className="display_none"
            value={val?.length ? "ok" : ""}
            required={required}
            ref={ref}
            onChange={stop}
            onClick={stop}
          />
          <input
            key="file"
            className="display_none"
            ref={hiddenRef}
            type="file"
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => readFiles(e.target.files).then(setVal)}
            multiple={multiple}
            required={required}
            accept={accept}
            capture={capture}
          />
        </div>
      </ToolTip>
    </label>
  );
}

export function DropZoneWidget(props: { accept?: string; multiple: boolean }) {
  const { accept = "", multiple } = props;

  const dropEvent = useEvent<FileItem[]>("drop");
  const ref = useRef<HTMLSpanElement>(null);

  const filterFiles = useCallback(
    (list: FileList | null) => {
      if (!list || list.length === 0) return [];
      const all = [...list];

      const mimes = accept
        .toLowerCase()
        .split(",")
        .map((x) => x.trim());

      const good = accept
        ? all.filter((f) => mimes.some((mime) => fileFilter(f, mime)))
        : all;

      return multiple ? good : good.slice(0, 1);
    },
    [multiple, accept]
  );

  useEffect(() => {
    const parent = ref.current?.closest(".region_wrapper");
    if (!parent) return;
    function onDrop(e) {
      e.preventDefault();
      readFiles(filterFiles(e.dataTransfer.files)).then(dropEvent);
      e.dataTransfer.clearData();
    }
    function onDragOver(e) {
      e.preventDefault();
    }
    parent.addEventListener("dragover", onDragOver);
    parent.addEventListener("drop", onDrop);
    return () => {
      parent.removeEventListener("dragover", onDragOver);
      parent.removeEventListener("drop", onDrop);
    };
  }, [ref.current]);

  return <span ref={ref} />;
}
