import {
  ButtonWidget,
  NavButtonWidget,
  CloseButtonWidget,
  DownloadButton,
} from "./ButtonWidget";
import { LinkWidget } from "./LinkWidget";
import { FormWidget } from "./FormWidget";
import { RecordWidget } from "./RecordWidget";
import { RegionWidget } from "./RegionWidget";
import { StringWidget } from "./StringWidget";
import { PasswordWidget } from "./PasswordWidget";
import { EmailWidget } from "./EmailWidget";
import { NumberWidget } from "./NumberWidget";
import { BooleanWidget } from "./BooleanWidget";
import { TableWidget } from "./TableWidget";
import { GalleryWidget } from "./GalleryWidget";
import { OneOfWidget } from "./OneOfWidget";
import { setWidgetImpls } from "./Widget";
import { SomeOfWidget } from "./SomeOfWidget";
import { ImageWidget } from "./ImageWidget";
import { CodeWidget } from "./CodeWidget";
import { ColorWidget } from "./ColorWidget";
import { VideoWidget } from "./VideoWidget";
import { AudioWidget } from "./AudioWidget";
import { DateWidget } from "./DateWidget";
import { DateTimeWidget } from "./DateTimeWidget";
import { TimeWidget } from "./TimeWidget";
import { TextAreaWidget } from "./TextAreaWidget";
import { ConfirmWidget } from "./ConfirmWidget";
import { AlertWidget } from "./AlertWidget";
import { LineChartWidget } from "./LineChartWidget";
import { BarChartWidget } from "./BarChartWidget";
import { PieChartWidget } from "./PieChartWidget";
import { NavWidget } from "./NavWidget";
import { RangeWidget } from "./RangeWidget";
import { ProgressBarWidget } from "./ProgressBarWidget";
import { RadioGroupWidget } from "./RadioGroupWidget";
import { SelectWidget } from "./SelectWidget";
import { CheckboxGroupWidget } from "./CheckboxGroupWidget";
import { MultiSelectWidget } from "./MultiSelectWidget";
import { TxtWidget } from "./TxtWidget";
import { URLWidget } from "./URLWidget";
import { ComboBoxWidget } from "./ComboBoxWidget";
import { SearchableSelect } from "./SearchableSelect";
import { MenuBar } from "./MenuBar";
import { Theme } from "./Theme";
import { InfoBar } from "./InfoBar";
import { PageParams } from "./PageParams";
import { FileUploadWidget, DropZoneWidget } from "./FileUploadWidget";
import { LocationSearchWidget } from "./LocationSearchWidget";
import { GoogleMapsWidget } from "./GoogleMaps";
import { JoystickWidget } from "./JoystickWidget";
import { InfoIcon } from "./InfoIcon";
import { Breadcrumb } from "./Breadcrumb";
import { History, PreventExit } from "./History";
import { CalendarWidget } from "./CalendarWidget";
import { NavBarWidget } from "./NavBarWidget";

export function prepareWidgets() {
  setWidgetImpls({
    Confirm: ConfirmWidget,
    Download: DownloadButton,
    Alert: AlertWidget,
    String: StringWidget,
    Password: PasswordWidget,
    Email: EmailWidget,
    Number: NumberWidget,
    Range: RangeWidget,
    Color: ColorWidget,
    URLField: URLWidget,
    Boolean: BooleanWidget,
    FileUpload: FileUploadWidget,
    DropZone: DropZoneWidget,
    LocationSearch: LocationSearchWidget,
    GoogleMaps: GoogleMapsWidget,
    JoystickWidget,
    OneOf: OneOfWidget,
    RadioGroup: RadioGroupWidget,
    Select: SelectWidget,
    SomeOf: SomeOfWidget,
    CheckboxGroup: CheckboxGroupWidget,
    MultiSelect: MultiSelectWidget,
    ComboBox: ComboBoxWidget,
    SearchableSelect,
    Record: RecordWidget,
    Button: ButtonWidget,
    CloseButton: CloseButtonWidget,
    NavButton: NavButtonWidget,
    Link: LinkWidget,
    Region: RegionWidget,
    Table: TableWidget,
    Txt: TxtWidget,
    Code: CodeWidget,
    Gallery: GalleryWidget,
    Form: FormWidget,
    Image: ImageWidget,
    Video: VideoWidget,
    Audio: AudioWidget,
    Date: DateWidget,
    DateTime: DateTimeWidget,
    Time: TimeWidget,
    TextArea: TextAreaWidget,
    LineChart: LineChartWidget,
    BarChart: BarChartWidget,
    PieChart: PieChartWidget,
    ProgressBar: ProgressBarWidget,
    Nav: NavWidget,
    NavBar: NavBarWidget,
    Calendar: CalendarWidget,
    MenuBar,
    Theme,
    Breadcrumb,
    InfoBar,
    InfoIcon,
    PageParams,
    History,
    PreventExit,
  });
}
