import { ReactNode, useEffect } from "react";

export type ErrorBoxProps = {
  title: string;
  children?: ReactNode;
  onMount?: () => void;
};

export function ErrorBox(props: ErrorBoxProps) {
  useEffect(() => {
    props.onMount?.();
  }, []);

  return (
    <div className="centered">
      <article>
        <h3>{props.title}</h3>
        {props.children}
      </article>
    </div>
  );
}
