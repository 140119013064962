import React, { useEffect, useState } from "react";
import { RowProps } from "../Row";
import { useControlledVal, useSetVal } from "../server_hooks";
import { MaybeLabel } from "./MaybeLabel";

type Props = {
  label?: string;
  disabled?: boolean;
} & RowProps;

const RADIUS = 75;
const KEYBOARD_RADIUS = 2 / 3;

function cart({ x, y }) {
  const l = x * x + y * y;

  if (l <= RADIUS * RADIUS) return { x: x / RADIUS, y: -y / RADIUS };

  const s = 1 / Math.sqrt(l);

  return { x: x * s, y: -y * s };
}

const ZERO = { x: 0, y: 0 };

export function JoystickWidget(props: Props) {
  const { label, disabled, rowHasLabel } = props;
  const { val, setVal } = useControlledVal<{ x: number; y: number }>("val");
  const [mouse, setMouse] = useState<{ x: number; y: number }>(ZERO);
  const [track, setTrack] = useState<boolean>(false);

  useEffect(() => {
    if (!track) return;

    function move(e) {
      setMouse((p) => {
        const n = { x: p.x + e.movementX, y: p.y + e.movementY };
        const newPos = cart(n);
        setVal(newPos, true, 200);
        return n;
      });
    }
    let timer;
    function up() {
      setTrack(false);
      // TODO: animate back
      setMouse(ZERO);
      setVal(ZERO);
    }

    window.addEventListener("mousemove", move);
    window.addEventListener("mouseup", up);
    return () => {
      window.removeEventListener("mousemove", move);
      window.removeEventListener("mouseup", up);
    };
  }, [track]);

  return (
    <div>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <div
        className="joystick"
        aria-disabled={disabled ? true : undefined}
        tabIndex={disabled ? -1 : 0}
        onKeyDown={
          track
            ? undefined
            : (e) => {
                switch (e.key.toLowerCase()) {
                  case "a":
                  case "arrowup":
                    setVal({ y: KEYBOARD_RADIUS, x: val ? val.x : 0 });
                    e.preventDefault();
                    break;
                  case "z":
                  case "arrowdown":
                    setVal({ y: -KEYBOARD_RADIUS, x: val ? val.x : 0 });
                    e.preventDefault();
                    break;
                  case "j":
                  case "arrowleft":
                    setVal({ x: -KEYBOARD_RADIUS, y: val ? val.y : 0 });
                    e.preventDefault();
                    break;
                  case "k":
                  case "arrowright":
                    setVal({ x: KEYBOARD_RADIUS, y: val ? val.y : 0 });
                    e.preventDefault();
                    break;
                }
              }
        }
        onKeyUp={
          track
            ? undefined
            : (e) => {
                switch (e.key.toLowerCase()) {
                  case "a":
                  case "arrowup":
                    setVal({ y: 0, x: val ? val.x : 0 });
                    e.preventDefault();
                    break;
                  case "z":
                  case "arrowdown":
                    setVal({ y: 0, x: val ? val.x : 0 });
                    e.preventDefault();
                    break;
                  case "j":
                  case "arrowleft":
                    setVal({ x: 0, y: val ? val.y : 0 });
                    e.preventDefault();
                    break;
                  case "k":
                  case "arrowright":
                    setVal({ x: 0, y: val ? val.y : 0 });
                    e.preventDefault();
                    break;
                }
              }
        }
        onMouseDown={
          disabled
            ? undefined
            : (e) => {
                setTrack(true);
                // @ts-ignore
                const rect = e.target.getBoundingClientRect();
                const coord = {
                  x: e.clientX - (rect.x + rect.width / 2),
                  y: e.clientY - (rect.y + rect.height / 2),
                };
                setMouse(coord);
                setVal(cart(coord));
              }
        }
      >
        <div className="bounds" />
        <div
          className="stick"
          style={
            val
              ? {
                  top: Math.round(-val.y * RADIUS) + "px",
                  left: Math.round(val.x * RADIUS) + "px",
                }
              : undefined
          }
        />
      </div>
    </div>
  );
}
