import { ReactElement } from "react";
import { Markdown } from "../Markdown";
import { MarkdownDeclaration } from "../protocol";

type Props = {
  display?: MarkdownDeclaration;
};

export function RecordWidget(props: Props): ReactElement {
  return (
    <span>
      <Markdown display={props.display} />
    </span>
  );
}
