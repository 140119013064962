import React, { ReactNode, useContext, useEffect } from "react";
import { RowProps } from "../Row";
import {
  useEvent,
  useLocked,
  ValContext,
  useControlledVal,
} from "../server_hooks";
import { Updatable } from "../useUpdatableProps";
import { FormContext } from "./FormWidget";
import { cx, renderValue, ZW_SPACE } from "./renderValue";
import { txt_cx } from "./TxtWidget";
import { Icon } from "./Icon";
import { BuiltinActions } from "@skymass/skymass/dist/protocol.mjs";
import { MaybeLabel } from "./MaybeLabel";
import {
  compileNavigationTarget,
  navigateTo,
  NavigationTarget,
} from "./History";

export const EM_SPACE = "\u{2003}";
type ButtonProps = {
  id: string;
  label?: string | Updatable<ReactNode>;
  disabled: undefined | boolean;
  autoFocus?: boolean;
  action?: BuiltinActions;
  icon?: string;
  validate?: boolean;
  size?: "xs" | "s" | "m";
  appearance?:
    | "button"
    | "button_outline"
    | "button_secondary"
    | "button_muted"
    | "link"
    | "pill";
} & RowProps;

type ButtonImplProps = ButtonProps & { busy?: boolean };

type DownloadProps = {
  file?: Updatable<{
    mimetype: string;
    filename: string;
    data: Uint8Array | ArrayBuffer | string;
  }>;
} & ButtonImplProps &
  RowProps;

export function ButtonWidget(props: ButtonProps) {
  const { vals } = useContext(ValContext);
  const form = useContext(FormContext);

  const { action, validate } = props;

  return (
    <Button
      props={props}
      onClick={() => {
        switch (action) {
          case undefined:
            form.onFormSubmit(
              vals.get("click")!.pathNode.toPathArray(),
              validate
            );
            break;
          case BuiltinActions.refresh:
            window.location.reload();
            break;
          default:
            action satisfies never;
            console.error(`Unrecognized action ${props.action}`);
            break;
        }
      }}
    />
  );
}

export function NavButtonWidget({
  nav,
  ...rest
}: Omit<ButtonProps, "action"> & { nav: NavigationTarget }) {
  return (
    <Button
      props={rest}
      onClick={() => {
        navigateTo(compileNavigationTarget(nav));
      }}
    />
  );
}

export function CloseButtonWidget(props: ButtonProps) {
  const onClick = useEvent("close");
  return <Button props={props} onClick={() => onClick(undefined)} />;
}

export function DownloadButton(props: DownloadProps) {
  const { val: wanted, setVal: setWanted } = useControlledVal("wanted");

  let adjusted = props;

  const { file } = props;
  if (wanted && (!file || file.pending || !file.val)) {
    adjusted = { ...props, busy: true };
  }

  // download the file
  useEffect(() => {
    if (!wanted) return;
    if (!file || file.pending || !file.val) return;

    const { data, filename, mimetype } = file.val;

    const blob = new File([data], filename, {
      type: mimetype,
    });

    const link = document.createElement("a");

    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = filename;

    document.body.appendChild(link);

    link.click();

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      link.remove();

      setWanted(false);
    }, 0);
  }, [wanted, file]);

  return (
    <Button
      props={adjusted}
      onClick={() => {
        setWanted(true);
      }}
    />
  );
}

function Button(given: { props: ButtonImplProps; onClick: () => void }) {
  const { props, onClick } = given;
  const locked = useLocked();

  const {
    id,
    label,
    rowHasLabel,
    icon,
    appearance = "button",
    size = "m",
    disabled,
    autoFocus,
    busy = undefined,
  } = props;

  // backwards compatibility
  const lblTxt =
    typeof label === "object" && "val" in label ? label.val : label;

  const ic = icon ? <Icon icon={icon} gap={lblTxt} /> : null;

  const lbl = lblTxt
    ? appearance === "pill"
      ? renderValue(lblTxt, "pill")
      : lblTxt
    : icon
    ? ZW_SPACE
    : `⚠️ [${id}]`;

  const dis = disabled || locked ? true : undefined;

  const tooltip =
    lblTxt || icon ? null : "Please supply a label or icon for this button";

  let btn, role;
  switch (appearance) {
    case "link":
      role = dis ? undefined : "link";
    case "pill":
      btn = (
        <span
          role={role}
          className={cx("button_like", "with_icon", txt_cx({ size }))}
          data-tooltip={tooltip}
          aria-busy={busy}
          aria-disabled={dis}
          autoFocus={autoFocus}
          onClick={dis ? undefined : onClick}
        >
          {ic}
          {lbl}
        </span>
      );
      break;
    case "button":
    case "button_outline":
    case "button_secondary":
    case "button_muted":
    default:
      btn = (
        <button
          className={txt_cx({ appearance, size })}
          data-tooltip={tooltip}
          aria-busy={busy}
          aria-disabled={dis}
          disabled={dis}
          autoFocus={autoFocus}
          onClick={onClick}
        >
          {ic}
          {lbl}
        </button>
      );
      break;
  }

  // TODO: handle this in the button group
  return rowHasLabel ? (
    <div>
      <MaybeLabel rowHasLabel />
      {btn}
    </div>
  ) : (
    btn
  );
}
