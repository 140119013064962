import React, { ReactNode, useState } from "react";
import { isEqualRenderable, Renderable } from "../Renderable";
import { RowProps } from "../Row";
import { useHandleMethodCall, useValidatedVal } from "../server_hooks";
import { Updatable } from "../useUpdatableProps";

import { renderValue } from "./renderValue";
import { ToolTip } from "./ToolTip";
import { MaybeLabel } from "./MaybeLabel";

type Option = Renderable | { value: Renderable; label: Renderable };

type Props = {
  id: string;
  label?: string;
  options: Option[];
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
} & RowProps;

function opt(i: number): string {
  return "o" + i;
}

function valueOf(option: Option): Renderable {
  return (option as any).value ?? option;
}

function labelOf(option: Option): Renderable {
  return (option as any).label ?? option;
}

export function SelectWidget(props: Props) {
  const {
    id,
    label,
    options,
    required,
    disabled,
    autoFocus,
    placeholder = "Select...",
    rowHasLabel,
  } = props;

  const [focus, setFocus] = useState(false);

  const { setVal, val, error, checkErrors, ref } =
    useValidatedVal<Renderable>();

  useHandleMethodCall("focus", () => {
    ref.current?.focus();
    ref.current?.scrollIntoView();
  });

  const mapped = Object.fromEntries(
    options.map((c, i) => [opt(i), valueOf(c)])
  );

  return (
    <label>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <ToolTip message={focus && !!error ? error : undefined}>
        <select
          aria-invalid={error === undefined || disabled ? undefined : !!error}
          ref={ref}
          onFocus={(e) => setFocus(true)}
          onBlur={(e) => {
            checkErrors();
            setFocus(false);
          }}
          required={required}
          disabled={disabled}
          autoFocus={autoFocus}
          style={{ marginTop: 0 }}
          value={opt(
            options.findIndex((c) => isEqualRenderable(valueOf(c), val))
          )}
          onChange={(e) => {
            const v = mapped[e.target.value];
            if (required && v === undefined) return;
            setVal(v);
          }}
        >
          <option key="-1" value="">
            {placeholder}
          </option>
          {options.map((c, i) => (
            <option key={opt(i)} value={opt(i)}>
              {renderValue(labelOf(c))}
            </option>
          ))}
        </select>
      </ToolTip>
    </label>
  );
}
