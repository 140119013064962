import { Widget } from "./Widget";

type Props = {
  text: string;
};

export function AlertWidget(props: Props) {
  return (
    <>
      <p>{props.text}</p>
      <div className="grid dialog">
        <span></span>
        <span></span>
        <Widget id="dismiss" />
      </div>
    </>
  );
}
