import React, { useEffect, useMemo, useState } from "react";
import { prepareWidgets } from "./widgets";
import {
  InitialPageParams,
  S2CConnectError,
  S2CProducerDied,
} from "./protocol";
import { RegionsContext, RegionWidget } from "./widgets/RegionWidget";
import { ConsumerManager, postponeRefreshes } from "./ConsumerManager";
import { ModalWrapper } from "./ModalWrapper";
import { DevHub } from "./DevHub";
import { useUpdatable } from "./useUpdatableProps";
import { ToastHub } from "./widgets/ToastHub";
import { AlertWrapper } from "./AlertWrapper";
import { requestLogin } from "./Header";
import { ErrorBox } from "./ErrorBox";
import { urlForPath } from "./paths";

type AppletProps = {
  host: string;
  subdomain: string;
  path: string;
  params: InitialPageParams;
  nav?: (path: string) => void;
};

export function Applet(props: AppletProps) {
  const { host, subdomain, path, params } = props;
  const [manager, setManager] = useState<null | ConsumerManager>(null);
  const [connectError, setConnectError] = useState<
    null | S2CConnectError | S2CProducerDied
  >(null);

  useEffect(() => {
    prepareWidgets();
  }, []);

  useEffect(() => {
    const consumer = new ConsumerManager({
      host,
      subdomain,
      page: path,
      params,
      onConnectError: setConnectError,
    });
    setManager(consumer);

    return () => consumer.disconnect();
  }, [host, subdomain, path]);

  if (connectError) {
    switch (connectError.code) {
      case 410:
        return (
          <ErrorBox title="Unrecognized URL">
            <a href="/">Home</a>
          </ErrorBox>
        );
      case 403:
        // TODO: if request access is allowed, then render a link for it
        return (
          <ErrorBox title="Restricted Access">
            <p>{`App "${path}" is restricted`}</p>
          </ErrorBox>
        );
      case 401:
        return (
          <ErrorBox title="Login Required">
            <p>{`App "${path}" requires login`}</p>
            <button onClick={(e) => requestLogin()}>Login</button>
          </ErrorBox>
        );
      case 502:
        return (
          <ErrorBox
            title="App Unavailable"
            // guarantee the UI will show for some minimal time
            onMount={() => postponeRefreshes(1000)}
          >
            <p>{`App "${path}" went offline.`}</p>
            <p>This page will refresh once it returns.</p>
          </ErrorBox>
        );
      case 503:
        return (
          <ErrorBox title="App Unavailable">
            <p>{`App "${path}" is currently offline.`}</p>
            <a href={urlForPath("")}>Directory</a>
          </ErrorBox>
        );
      default:
        connectError satisfies never;
    }
  }

  return (
    <>
      {manager && <AppletContent manager={manager} />}
      {manager && <ToastHub latestToast={manager.app.latestToast} />}
      <DevHub />
    </>
  );
}

function AppletContent({ manager }: { manager: ConsumerManager }) {
  const structure = useUpdatable(manager.app.structure);

  const { regions, rootRegionId, modalIds, alertIds } = structure.val!;

  const modalId = modalIds.length ? modalIds[modalIds.length - 1] : null;
  const alertId = alertIds.length ? alertIds[0] : null;

  const context = useMemo(() => ({ regions }), [regions]);

  // todo: perhaps a loading screen?
  if (!rootRegionId) return null;

  return (
    <RegionsContext.Provider value={context}>
      <RegionWidget id={rootRegionId} />
      <ModalWrapper modalId={modalId} />
      <AlertWrapper alertId={alertId} />
    </RegionsContext.Provider>
  );
}
