// TODO: read these from css vars
const CategoricalColors = [
  "#fd7f6f",
  "#7eb0d5",
  "#b2e061",
  "#bd7ebe",
  "#ffb55a",
  "#ffee65",
  "#beb9db",
  "#fdcce5",
  "#8bd3c7",
];

export function cat_color(index: number): string {
  return CategoricalColors[index % CategoricalColors.length];
}

// cat-1 -> index = 0
export function color_by_cat(cat: string | undefined): string | undefined {
  if (!cat) return undefined;
  const index = parseInt(cat.split("-")[1] || "1");
  return cat_color(index - 1);
}
