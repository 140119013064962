import React, { ReactNode, useState } from "react";
import { isEqualRenderable, Renderable } from "../Renderable";
import { RowProps } from "../Row";
import { useControlledVal } from "../server_hooks";
import { Updatable } from "../useUpdatableProps";

import { renderValue } from "./renderValue";
import { MaybeLabel } from "./MaybeLabel";

type Props = {
  id: string;
  label?: string;
  options: Renderable[];
  required?: boolean;
  placeholder?: string;
} & RowProps;

function includes(a: Renderable[], v: Renderable): boolean {
  return !!a.find((x) => isEqualRenderable(x, v));
}

function opt(i: number): string {
  return "o" + i;
}

let ID = 100;

export function SomeOfWidget(props: Props) {
  const { label, options, required, placeholder, rowHasLabel } = props;
  const { val, setVal } = useControlledVal<Renderable[]>();
  const [id] = useState(ID++);
  if (options.length < 6) {
    return (
      <fieldset key={id}>
        <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
        {options.map((c) => (
          <label key={c.toString()}>
            <input
              name={`some_of_${id}`}
              checked={includes(val, c)}
              type="checkbox"
              onChange={(e) => {
                setVal(
                  e.target.checked
                    ? [...val, c]
                    : val.filter((x) => !isEqualRenderable(x, c))
                );
              }}
            />
            {renderValue(c)}
          </label>
        ))}
      </fieldset>
    );
  } else {
    const selectedOptions = [];
    // we can't put renderables directly into the DOM
    // so we build a map of string option (opt(i)) -> actual option
    // and use it to look up the actual value when we change the selection
    // while we are iterating through the options, we also build up the selection
    // since it needs to refer to the string options
    const mapped = Object.fromEntries(
      options.map((c, i) => {
        const option = opt(i);
        if (includes(val, c)) {
          selectedOptions.push(option);
        }
        return [option, c];
      })
    );

    return (
      <label>
        <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
        <select
          style={{ marginTop: 0 }}
          multiple
          value={selectedOptions}
          onChange={(e) =>
            setVal(
              Array.from(
                e.target.selectedOptions,
                (option) => mapped[option.value]
              )
            )
          }
        >
          {options.map((c, i) => (
            <option key={opt(i)} value={opt(i)}>
              {renderValue(c)}
            </option>
          ))}
        </select>
      </label>
    );
  }
}
