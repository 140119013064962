import React, { ReactNode, useEffect, useState } from "react";
import { isEqualRenderable, Renderable } from "../Renderable";
import { RowProps } from "../Row";
import { useHandleMethodCall, useValidatedVal } from "../server_hooks";
import { Updatable } from "../useUpdatableProps";

import { renderValue } from "./renderValue";
import { ToolTip } from "./ToolTip";
import { MaybeLabel } from "./MaybeLabel";

type Option = Renderable | { value: Renderable; label: Renderable };

type Props = {
  id: string;
  label?: string;
  options: Option[];
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  layout: "vertical" | "horizontal";
  other?: boolean | string;
} & RowProps;

function includes(a: Renderable[], v: Renderable): boolean {
  return !!a.find((x) => isEqualRenderable(x, v));
}

function valueOf(option: Option): Renderable {
  return (option as any).value ?? option;
}

function labelOf(option: Option): Renderable {
  return (option as any).label ?? option;
}

function areEqual(a: Renderable[] = [], b: Renderable[] = []) {
  return (
    a.length === b.length && a.every((v, i) => valueOf(v) === valueOf(b[i]))
  );
}

function isInside(e) {
  if (e.currentTarget.contains(e.relatedTarget)) return true;
  if (e.currentTarget === e.relatedTarget) return true;
  return false;
}

export function CheckboxGroupWidget(props: Props) {
  // TODO: support required
  const {
    id,
    label,
    options,
    rowHasLabel,
    required,
    disabled,
    autoFocus,
    layout = "vertical",
    other,
  } = props;

  const otherLabel = other
    ? typeof other === "boolean"
      ? "Other..."
      : other
    : undefined;

  const [otherIsSelected, setOtherIsSelected] = useState<boolean>(false);

  const [otherVal, setOtherVal] = useState<string>("");

  const [focus, setFocus] = useState<boolean | undefined>(undefined);

  const otherValTrimmed = otherVal.trim();

  const { setVal, val, error, checkErrors, ref } = useValidatedVal<
    Renderable[]
  >(
    "val",
    (values) => {
      if (required && !values?.length)
        return "Please select one of these options.";
      return "";
    },
    [required]
  );

  const [mainVal, setMainVal] = useState<Renderable[]>(val || []);

  useEffect(() => {
    const v =
      otherIsSelected && otherValTrimmed
        ? [...mainVal, otherValTrimmed]
        : mainVal;

    if (areEqual(v, val)) return;

    setVal(v);
  }, [mainVal, otherValTrimmed, otherIsSelected]);

  // focus the other input when otherIsSelected becomes true
  useEffect(() => {
    if (error) checkErrors();
    if (otherIsSelected) ref.current?.focus();
  }, [otherIsSelected]);

  // check errors on blur,
  // focus the other field if it is selected
  useEffect(() => {
    if (focus) {
      if (otherIsSelected) ref.current?.focus();
      return;
    }
    // skip first render
    if (focus === undefined) return;
    checkErrors();
  }, [focus]);

  useHandleMethodCall("focus", () => {
    ref.current?.focus();
    ref.current?.scrollIntoView();
  });

  return (
    <fieldset
      className="checkbox_group"
      key={id}
      tabIndex={disabled ? -1 : 0}
      onFocus={(e) => setFocus(true)}
      onBlur={(e) => {
        if (isInside(e)) return;
        setFocus(false);
      }}
    >
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <ToolTip inline message={!otherIsSelected && focus ? error : undefined}>
        <div
          className={
            layout === "horizontal"
              ? "checkbox_horizontal"
              : "checkbox_vertical"
          }
        >
          {options.map((option, i) => {
            const c = valueOf(option);
            const isRef = !otherIsSelected && !i;
            const checked = includes(mainVal, c);
            return (
              <label key={i} className="checkbox_label">
                <input
                  ref={isRef ? ref : undefined}
                  aria-invalid={ariaInvalid(error, !!required)}
                  checked={checked}
                  disabled={disabled}
                  autoFocus={autoFocus && i === 0}
                  type="checkbox"
                  onChange={(e) => {
                    setMainVal(
                      e.target.checked
                        ? [...mainVal, c]
                        : mainVal.filter((x) => !isEqualRenderable(x, c))
                    );
                  }}
                />
                {renderValue(labelOf(option))}
              </label>
            );
          })}
          {other ? (
            <ToolTip message={otherIsSelected && focus ? error : undefined}>
              <label
                className="checkbox_label"
                key="__sm_other"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div style={{ marginTop: "-0.125em" }}>
                  <input
                    type="checkbox"
                    checked={otherIsSelected}
                    disabled={disabled}
                    onChange={(e) => setOtherIsSelected(e.target.checked)}
                  />
                </div>
                <input
                  style={{ margin: 0 }}
                  type="text"
                  aria-invalid={otherIsSelected && error ? true : undefined}
                  ref={otherIsSelected ? ref : undefined}
                  required
                  pattern=".*\S.*"
                  disabled={!otherIsSelected || disabled}
                  placeholder={otherLabel}
                  onChange={(e) => setOtherVal(e.target.value)}
                  value={otherVal}
                />
              </label>
            </ToolTip>
          ) : null}
        </div>
      </ToolTip>
    </fieldset>
  );
}

// error?     cons?   aria-invalid
// undefined  true    undefined
// undefined  false   undefined
// true       true    true
// true       false   true
// false      true    false
// false      false   undefined
export function ariaInvalid(
  error: string | undefined,
  hasConstraints: boolean
) {
  return error === undefined
    ? undefined
    : error
    ? true
    : hasConstraints
    ? false
    : undefined;
}
