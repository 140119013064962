import { RowProps } from "../Row";
import { Bar } from "react-chartjs-2";
import { ImgSize, imgSizeToWidth } from "./ImgSize";
import { darken } from "color2k";
import { cat_color } from "./Colors";
import React from "react";
import {
  ChartData,
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { MaybeLabel } from "./MaybeLabel";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type BarProps = {
  label?: string;
  caption?: string;
  size?: ImgSize;
  data: ChartData<"bar">;
} & RowProps;

export function BarChartWidget({
  data,
  label,
  caption,
  size = "m",
  rowHasLabel,
  inRow,
}: BarProps) {
  const options: any = {
    animation: false,

    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    plugins: {
      legend: {
        labels: {
          boxWidth: 10,
          filter: (legend: { text?: string }) => !!legend.text,
        },
        maxHeight: 24, // prevent wrapped legend height from squeeshing the graph
        position: "bottom",
      },
    },
  };

  if (inRow) {
    options.maintainAspectRatio = false;
  } else {
    options.aspectRatio = 2;
  }

  data = {
    ...data,
    datasets: data.datasets.map((ds, index) => {
      if (ds.backgroundColor || ds.borderColor) return ds;
      const c = cat_color(index);
      return { ...ds, backgroundColor: c, borderColor: darken(c, 0.2) };
    }),
  };

  return (
    <figure>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <div className={"ui_chart " + size}>
        <Bar data={data} options={options} />
      </div>
      {caption && <figcaption>{caption}</figcaption>}
    </figure>
  );
}
