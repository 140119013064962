import React, { useState, useEffect, useRef } from "react";
import { RowProps } from "../Row";
import { useEvent, useSetVal } from "../server_hooks";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Marker } from "@googlemaps/adv-markers-utils";
import { MaybeLabel } from "./MaybeLabel";
import { SMCatColor } from "./renderValue";
import { color_by_cat } from "./Colors";
import root from "react-shadow";

type LatLng = { lat: number; lng: number };

type Data = {
  id: string;
  title?: string;
  label?: string;
  icon?: string;
  color?: SMCatColor;
  position: LatLng;
};

type WeightedMarker = { lat: number; lng: number; weight?: number };

type Props = {
  id: string;
  label?: string;
  apiKey: string;
  mapId: string;
  disabled?: boolean;
  center: LatLng;
  zoom?: number | "contain";
  type: google.maps.MapTypeId;
  size: "s" | "m" | "l";
  markers?: Data[];
  heatmap?: {
    data: WeightedMarker[];
  };
  debounce?: number;
} & RowProps;

export function GoogleMapsWidget(props: Props) {
  const {
    id,
    label,
    apiKey,
    mapId = "1a2126a0780b075b",
    disabled,
    center,
    zoom,
    type,
    size = "m",
    markers,
    heatmap,
    debounce,
    rowHasLabel,
  } = props;

  const render = (status: Status) => {
    switch (status) {
      case Status.LOADING:
        return <div aria-busy className={`google_maps_wrapper ${size}`} />;
      case Status.FAILURE:
        return (
          <div aria-invalid className={`google_maps_wrapper error ${size}`}>
            Error loading Google Maps
          </div>
        );
      case Status.SUCCESS:
        return (
          <root.div className={`google_maps_wrapper ${size}`}>
            <Map
              markers={markers}
              heatmap={heatmap}
              mapId={mapId}
              center={center}
              zoom={zoom}
              size={size}
            />
          </root.div>
        );
    }
  };

  const map = (
    <Wrapper
      apiKey={apiKey}
      render={render}
      libraries={["visualization", "marker"]}
    />
  );

  return (
    <figure>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      {map}
    </figure>
  );
}

function avg(list: number[]) {
  return list.length
    ? list.reduce((prev, curr) => prev + curr, 0) / list.length
    : 0;
}

function Map({
  mapId,
  center,
  zoom = 5,
  markers,
  heatmap,
  size,
}: {
  mapId: string;
  center: LatLng;
  zoom?: number | "contain";
  markers?: Data[];
  heatmap?: {
    data: WeightedMarker[];
  };
  size?: "s" | "m" | "l";
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const markersById = useRef<{ [key: string]: Marker }>({});
  const heatMapObj = useRef<google.maps.visualization.HeatmapLayer>();

  const zoomVal = useSetVal<number | undefined>("zoom");
  const centerVal = useSetVal<LatLng | undefined>("center");
  const onMarkerClick = useEvent<Data>("markerClick");
  const onClick = useEvent<LatLng | undefined>("click");

  // update markers
  useEffect(() => {
    if (!map) return;
    const byId = markersById.current;
    const toDel = new Set(Object.keys(byId));
    markers?.forEach((m) => {
      const old = byId[m.id];
      if (old) {
        old.title = m.title;
        old.icon = m.icon;
        old.glyph = m.label;
        old.color = color_by_cat(m.color);
        old.position = m.position;
        old.map = map;
        toDel.delete(m.id);
      } else {
        // const legacy = new google.maps.Marker({ position: m.position, map });
        const marker = new Marker({
          title: m.title,
          icon: m.icon,
          glyph: m.label,
          color: color_by_cat(m.color),
          position: m.position,
          map,
        });
        marker.addListener("click", () => {
          onMarkerClick(m);
        });
        byId[m.id] = marker;
      }
    });
    toDel.forEach((id) => {
      const marker = byId[id];
      marker.map = null;
      google.maps.event.clearInstanceListeners(marker);
      delete byId[id];
    });
  }, [markers, map]);

  // update heatmap
  useEffect(() => {
    const old = heatMapObj.current;
    heatMapObj.current = undefined;
    if (heatmap && map) {
      heatMapObj.current = new google.maps.visualization.HeatmapLayer({
        data: heatmap.data.map((ll) =>
          ll.weight
            ? { location: new google.maps.LatLng(ll), weight: ll.weight }
            : new google.maps.LatLng(ll)
        ),
      });

      heatMapObj.current?.setMap(map);
    }
    if (old) {
      old.setMap(null);
    }
  }, [heatmap, map]);

  useEffect(() => {
    if (!map) return;
    map.panTo(center);
  }, [center.lat, center.lng, map, markers]);

  useEffect(() => {
    if (!map) return;
    if (zoom === "contain") {
      if (markers?.length) {
        const bounds = new google.maps.LatLngBounds();
        markers.forEach((marker) => bounds.extend(marker.position));
        map.fitBounds(bounds);
      } else {
        map.setZoom(5);
      }
    } else {
      map.setZoom(zoom);
    }

    zoomVal(map.getZoom());
  }, [zoom, map, markers]);

  useEffect(() => {
    if (!ref.current) return;

    const map = new window.google.maps.Map(ref.current, {
      center,
      controlSize: 24,
      mapId,
    });

    if (zoom === "contain") {
      if (markers?.length) {
        const bounds = new google.maps.LatLngBounds();
        markers.forEach((marker) => bounds.extend(marker.position));
        map.fitBounds(bounds);
      } else {
        map.setZoom(5);
      }
    } else {
      map.setZoom(zoom);
    }

    map.addListener("zoom_changed", () => zoomVal(map.getZoom()));
    map.addListener("center_changed", () => {
      centerVal(map.getCenter()?.toJSON());
    });

    map.addListener("click", (e: google.maps.MapMouseEvent) => {
      onClick(e.latLng?.toJSON());
    });

    zoomVal(map.getZoom());
    centerVal(map.getCenter()?.toJSON());

    setMap(map);
  }, [ref.current]);

  useEffect(() => {
    return () => {
      if (map) {
        google.maps.event.clearInstanceListeners(map);
      }
      Object.values(markersById.current).forEach((m) => (m.map = null));
      markersById.current = {};
      heatMapObj.current?.setMap(null);
    };
  }, []);

  return <div ref={ref} style={{ width: "100%", height: "100%" }} />;
}
