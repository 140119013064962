import React, {
  ReactNode,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { isEqualRenderable, Renderable } from "../Renderable";
import {
  autoUpdate,
  size,
  flip,
  useId,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  FloatingFocusManager,
  FloatingPortal,
} from "@floating-ui/react";
import { RowProps } from "../Row";
import { useHandleMethodCall, useValidatedVal } from "../server_hooks";
import { Updatable } from "../useUpdatableProps";
import { renderValue, cx } from "./renderValue";
import { ToolTip } from "./ToolTip";
import { MaybeLabel } from "./MaybeLabel";
import {
  ColorOption,
  colorOf,
  labelOf,
  optionForValue,
  stop,
  valueOf,
} from "./ComboBoxWidget";
import { ariaInvalid } from "./CheckboxGroupWidget";

type Props = {
  id: string;
  label?: string;
  options: Updatable<ColorOption[]>;
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  other?: boolean;
  placeholder?: string;
} & RowProps;

type ItemProps = {
  children: ReactNode;
  active: boolean;
};

const Item = forwardRef<
  HTMLLIElement,
  ItemProps & React.HTMLProps<HTMLLIElement>
>(({ children, active, ...rest }, ref) => (
  <li
    ref={ref}
    role="option"
    id={useId()}
    aria-selected={active}
    {...rest}
    className={active ? "selected" : undefined}
  >
    {children}
  </li>
));

export function SearchableSelect(props: Props) {
  const {
    id,
    label,
    rowHasLabel,
    options,
    other = false,
    placeholder,
    required,
    disabled,
    autoFocus,
  } = props;

  const { val: optionsList = [], pending: optionsPending } = Array.isArray(
    options
  )
    ? { val: options, pending: false }
    : options;

  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { val, setVal, error, checkErrors, ref, focusRef } = useValidatedVal<
    Renderable | undefined
  >();

  useHandleMethodCall("focus", () => {
    focusRef.current?.focus();
  });

  // currently highlighted item in the dropdown
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  // currently highlighted item in the values list
  const [activePill, setActivePill] = useState<boolean>(false);

  const listRef = useRef<Array<HTMLElement | null>>([]);

  function updateSelected(value: Renderable | undefined) {
    if (ref.current) {
      ref.current.value = value ? "value" : "";
    }
    setVal(value);
    setActivePill(activePill && !!value);
  }

  const { x, y, strategy, refs, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
    middleware: [
      flip(),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 0,
      }),
    ],
  });

  const role = useRole(context, { role: "listbox" });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [role, dismiss, listNav]
  );

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setInputValue(value);

    if (value) {
      setOpen(true);
      setActiveIndex(0);
    } else {
      setOpen(false);
    }
  }

  function reset() {
    setInputValue("");
    setActiveIndex(null);
    setOpen(false);
    setActivePill(false);
  }

  const trimmed = inputValue.trim();
  const needle = trimmed.toLowerCase();
  const custom: ColorOption[] =
    needle &&
    other &&
    trimmed !== val &&
    !optionsList.find(
      (item: ColorOption) => labelOf(item).toString().toLowerCase() === needle
    )
      ? [trimmed]
      : [];

  const subset: ColorOption[] = needle
    ? optionsList.filter((item) => {
        const lower = renderValue(labelOf(item)).toString();
        return lower !== val && lower.toLowerCase().includes(needle);
      })
    : val
    ? optionsList.filter((item) => !isEqualRenderable(valueOf(item), val))
    : optionsList;

  const matches = [...custom, ...subset.slice(0, 50)];

  let pill;
  if (val) {
    // default to selectedItem if other is set
    const option = optionForValue(optionsList, val) || val;
    const label = labelOf(option);
    pill = (
      <code
        className={cx(
          activePill ? "value_selected" : undefined,
          colorOf(option)
        )}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <span className="nowrap">{renderValue(label)}</span>{" "}
        <span
          onClick={(e) => {
            stop(e);
            updateSelected(undefined);
          }}
        >
          &#10005;
        </span>
      </code>
    );
  }

  useEffect(() => {
    if (error !== undefined) checkErrors();
  }, [!!val, error]);

  return (
    <div className="combobox" key={id} onClick={stop} aria-disabled={disabled}>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <ToolTip message={focus && !!error ? error : undefined}>
        <details role="list" open={open}>
          <summary
            ref={refs.setPositionReference}
            aria-invalid={ariaInvalid(error, !!required)}
            tabIndex={-1}
            role={undefined}
            onFocus={(e) => {
              refs.domReference.current?.focus();
              setFocus(true);
            }}
            onBlur={(e) => {
              checkErrors();
              setFocus(false);
            }}
            onMouseDown={(e) => {
              setOpen((open) => !open);
            }}
          >
            <div className="pills">
              {pill}
              <input
                {...getReferenceProps({
                  ref: (el: HTMLInputElement) => {
                    refs.setReference(el);
                    focusRef.current = el;
                  },
                  onChange,
                  value: inputValue,
                  type: "text",
                  style: { minWidth: "5em" },
                  placeholder,
                  disabled,
                  autoFocus,
                  "aria-autocomplete": "list",
                  onFocus(event) {
                    stop(event);
                    setOpen(true);
                  },
                  onKeyDown(event) {
                    switch (event.key) {
                      case "Enter": {
                        if (activeIndex != null && matches[activeIndex]) {
                          stop(event); // so we don't submit the form
                          updateSelected(valueOf(matches[activeIndex]));
                          reset();
                        }
                        break;
                      }
                      case "Delete":
                      case "Backspace": {
                        setOpen(false);
                        if (inputValue.trim()) return;
                        if (!val) return;
                        updateSelected(undefined);
                        break;
                      }
                      case "ArrowLeft": {
                        if (!val) return;
                        if (inputValue.trim()) return;
                        setActivePill(true);
                        break;
                      }
                      case "ArrowRight": {
                        if (!activePill) return;
                        setActivePill(false);
                        refs.domReference.current?.focus();
                        break;
                      }
                    }
                  },
                })}
              />
            </div>
          </summary>
        </details>
        <input
          ref={ref}
          type="text"
          className="display_none"
          required={required}
          value={val ? "value" : ""}
          onChange={stop}
        />
      </ToolTip>
      {open && (
        <FloatingPortal>
          <FloatingFocusManager
            context={context}
            initialFocus={-1}
            visuallyHiddenDismiss
          >
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  position: strategy,
                  left: x ?? 0,
                  top: y ?? 0,
                  overflowY: "auto",
                  boxShadow: "var(--card-box-shadow)",
                },
              })}
            >
              <ul role="menu">
                {matches.length ? (
                  matches.map((item, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Item
                      {...getItemProps({
                        key: `${renderValue(valueOf(item))}${index}`,
                        ref(node) {
                          listRef.current[index] = node;
                        },
                        onClick() {
                          updateSelected(valueOf(item));
                          setInputValue("");
                          refs.domReference.current?.focus();
                          setOpen(false);
                          setActivePill(false);
                        },
                      })}
                      active={activeIndex === index}
                    >
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        {renderValue(labelOf(item))}
                      </a>
                    </Item>
                  ))
                ) : optionsPending ? (
                  <li aria-busy={true}>Loading...</li>
                ) : inputValue ? (
                  <li>No matches for "{inputValue}"</li>
                ) : (
                  <li>No matches</li>
                )}
              </ul>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  );
}
