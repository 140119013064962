import React, { useEffect, useRef, useState, forwardRef, useMemo } from "react";
import {
  autoUpdate,
  size,
  flip,
  useId,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  FloatingFocusManager,
  FloatingPortal,
} from "@floating-ui/react";

import { isEqualRenderable, Renderable } from "../Renderable";
import { RowProps } from "../Row";
import { useHandleMethodCall, useValidatedVal } from "../server_hooks";
import { bgcolor_mute_class, renderValue, cx, SMColor } from "./renderValue";
import { ToolTip } from "./ToolTip";
import { MaybeLabel } from "./MaybeLabel";
import { ariaInvalid } from "./CheckboxGroupWidget";

export type ColorOption =
  | Renderable
  | { value: Renderable; label: Renderable; color: SMColor };

type Props = {
  id: string;
  label?: string;
  options: ColorOption[];
  required?: boolean;
  other?: boolean;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
} & RowProps;

export function stop(e) {
  e.preventDefault();
  e.stopPropagation();
}

export function optionForValue(options: ColorOption[], value: Renderable) {
  return options.find((option) => isEqualRenderable(valueOf(option), value));
}

export function removeOption(current: Renderable[], option: ColorOption) {
  return current.filter((item) => item !== option);
}

export function valueOf(option: ColorOption): Renderable {
  return (option as any).value ?? option;
}

export function labelOf(option: ColorOption): Renderable {
  return (option as any).label ?? option;
}

export function colorOf(option: ColorOption): string {
  const color = (option as any).color;
  return color ? `bgcolor-${color}-mute` : bgcolor_mute_class(labelOf(option));
}

interface ItemProps {
  children: React.ReactNode;
  active: boolean;
}

const Item = forwardRef<
  HTMLLIElement,
  ItemProps & React.HTMLProps<HTMLLIElement>
>(({ children, active, ...rest }, ref) => (
  <li
    ref={ref}
    role="option"
    id={useId()}
    aria-selected={active}
    {...rest}
    className={active ? "selected" : undefined}
  >
    {children}
  </li>
));

function clip(value: number | null, list: any[]) {
  if (value === null) return null;
  const l = list.length;
  if (l === 0) return null;
  return Math.max(0, Math.min(value, l - 1));
}

export function ComboBoxWidget(props: Props) {
  const {
    id,
    options,
    label,
    other = false,
    placeholder,
    required,
    rowHasLabel,
    disabled,
    autoFocus,
  } = props;

  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const {
    val: selectedItems,
    setVal: setSelectedItems,
    error,
    checkErrors,
    ref,
    focusRef,
  } = useValidatedVal<Renderable[]>();

  useHandleMethodCall("focus", () => {
    focusRef.current?.focus();
  });

  // currently highlighted item in the dropdown
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  // currently highlighted item in the values list
  const [activePill, setActivePill] = useState<number | null>(null);

  const listRef = useRef<Array<HTMLElement | null>>([]);

  const { val: optionsList = [], pending: optionsPending } = Array.isArray(
    options
  )
    ? { val: options, pending: false }
    : options;

  function updateSelected(list: Renderable[]) {
    if (ref.current) {
      ref.current.value = list.length ? "value" : "";
    }
    setSelectedItems(list);
    setActivePill(clip(activePill, list));
  }

  const { x, y, strategy, refs, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
    middleware: [
      flip(),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 0,
      }),
    ],
  });

  const role = useRole(context, { role: "listbox" });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [role, dismiss, listNav]
  );

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setInputValue(value);

    if (value) {
      setOpen(true);
      setActiveIndex(0);
    } else {
      setOpen(false);
    }
  }

  function reset() {
    setInputValue("");
    setActiveIndex(null);
    setOpen(false);
    setActivePill(null);
  }

  const selectedSet = useMemo(() => new Set(selectedItems), [selectedItems]);

  const trimmed = inputValue.trim();
  const needle = trimmed.toLowerCase();
  const custom: ColorOption[] =
    needle &&
    other &&
    !selectedSet.has(trimmed) &&
    !options.find(
      (item: ColorOption) => labelOf(item).toString().toLowerCase() === needle
    )
      ? [trimmed]
      : [];

  const subset: ColorOption[] = needle
    ? options.filter(
        (item) =>
          !selectedSet.has(valueOf(item)) &&
          labelOf(item).toString().toLowerCase().includes(needle)
      )
    : options.filter((item) => !selectedSet.has(valueOf(item)));

  useEffect(() => {
    if (error !== undefined) checkErrors();
  }, [!!selectedItems?.length, error]);

  const matches = [...custom, ...subset.slice(0, 50)];

  return (
    <div className="combobox" key={id} onClick={stop} aria-disabled={disabled}>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <ToolTip message={focus && !!error ? error : undefined}>
        <details role="list" open={open}>
          <summary
            ref={refs.setPositionReference}
            aria-invalid={ariaInvalid(error, !!required)}
            tabIndex={-1}
            role={undefined}
            onFocus={(e) => {
              refs.domReference.current?.focus();
              setFocus(true);
            }}
            onBlur={(e) => {
              checkErrors();
              setFocus(false);
            }}
            onMouseDown={(e) => {
              setOpen((open) => !open);
            }}
          >
            <div className="pills">
              {selectedItems?.map((item, index) => {
                // default to selectedItem if other is set
                const option = optionForValue(options, item) || item;
                const label = labelOf(option);
                return (
                  <code
                    className={cx(
                      colorOf(option),
                      activePill === index ? "value_selected" : undefined
                    )}
                    onMouseDown={(e) => e.stopPropagation()}
                    key={`selected-item-${index}`}
                  >
                    <span className="nowrap">{renderValue(label)}</span>{" "}
                    <span
                      onClick={(e) => {
                        stop(e);
                        updateSelected(removeOption(selectedItems, item));
                      }}
                    >
                      &#10005;
                    </span>
                  </code>
                );
              })}
              <input
                {...getReferenceProps({
                  ref: (el: HTMLInputElement) => {
                    refs.setReference(el);
                    focusRef.current = el;
                  },
                  onChange,
                  value: inputValue,
                  type: "text",
                  style: { minWidth: "5em" },
                  placeholder,
                  disabled,
                  autoFocus,
                  "aria-autocomplete": "list",
                  onFocus(event) {
                    setOpen(true);
                  },
                  onKeyDown(event) {
                    switch (event.key) {
                      case "Enter": {
                        if (activeIndex != null && matches[activeIndex]) {
                          stop(event); // so we don't submit the form
                          updateSelected([
                            ...(selectedItems || []),
                            valueOf(matches[activeIndex]),
                          ]);
                          reset();
                        }
                        break;
                      }
                      case "Delete":
                      case "Backspace": {
                        setOpen(false);
                        if (inputValue.trim()) return;
                        if (!selectedItems?.length) return;
                        if (activePill === null) {
                          selectedItems.pop();
                          updateSelected([...(selectedItems || [])]);
                        } else {
                          updateSelected(
                            selectedItems?.filter(
                              (item, index) => index !== activePill
                            )
                          );
                        }
                        break;
                      }
                      case "ArrowLeft": {
                        if (!selectedItems?.length) return;
                        if (inputValue.trim()) return;

                        if (activePill === null) {
                          setActivePill(selectedItems.length - 1);
                        } else {
                          setActivePill(Math.max(0, activePill - 1));
                        }
                        break;
                      }
                      case "ArrowRight": {
                        if (!selectedItems?.length) return;
                        if (activePill === null) return;

                        if (activePill < selectedItems.length - 1) {
                          setActivePill(activePill + 1);
                        } else {
                          setActivePill(null);
                          refs.domReference.current?.focus();
                        }
                        break;
                      }
                    }
                  },
                })}
              />
            </div>
          </summary>
        </details>
        <input
          ref={ref}
          type="text"
          className="display_none"
          required={required}
          value={selectedItems?.length ? "value" : ""}
          onChange={stop}
        />
      </ToolTip>
      {open && (
        <FloatingPortal>
          <FloatingFocusManager
            context={context}
            initialFocus={-1}
            visuallyHiddenDismiss
          >
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  position: strategy,
                  left: x ?? 0,
                  top: y ?? 0,
                  overflowY: "auto",
                  boxShadow: "var(--card-box-shadow)",
                },
              })}
            >
              <ul role="menu">
                {matches.length ? (
                  matches.map((item, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Item
                      {...getItemProps({
                        key: `${valueOf(item)}${index}`,
                        ref(node) {
                          listRef.current[index] = node;
                        },
                        onClick() {
                          updateSelected([
                            ...(selectedItems || []),
                            valueOf(item),
                          ]);
                          setInputValue("");
                          setOpen(false);
                          setActivePill(null);
                          refs.domReference.current?.focus();
                        },
                      })}
                      active={activeIndex === index}
                    >
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        {renderValue(labelOf(item))}
                      </a>
                    </Item>
                  ))
                ) : optionsPending ? (
                  <li aria-busy={true}>Loading...</li>
                ) : inputValue ? (
                  <li>No matches for "{inputValue}"</li>
                ) : (
                  <li>No matches</li>
                )}
              </ul>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  );
}
