import React, { ReactNode, useState } from "react";
import { RowProps } from "../Row";
import { useHandleMethodCall, useValidatedVal } from "../server_hooks";
import { Updatable } from "../useUpdatableProps";
import { ToolTip } from "./ToolTip";
import { valOrLiteral, MaybeLabel } from "./MaybeLabel";
import { ariaInvalid } from "./CheckboxGroupWidget";

type Props = {
  label?: string;
  placeholder?: Updatable<string> | string;
  min?: number;
  max?: number;
  step?: number | "any";
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  debounce?: number;
  postfix?: string;
  prefix?: string;
} & RowProps;

function ph(min, max) {
  return min != null
    ? max != null
      ? `A number between ${min} and ${max}`
      : `A number greater than or equal to ${min}`
    : max != null
    ? `A number less than or equal to ${max}`
    : undefined;
}

export function NumberWidget(props: Props) {
  const {
    label,
    min,
    max,
    step,
    placeholder,
    required,
    readOnly,
    disabled,
    autoFocus,
    rowHasLabel,
    debounce = 0,
    postfix,
    prefix,
  } = props;
  const [focus, setFocus] = useState(false);
  const { setVal, val, error, checkErrors, ref } = useValidatedVal<
    "" | number
  >();

  useHandleMethodCall("focus", () => {
    ref.current?.focus();
    ref.current?.scrollIntoView();
  });

  const post = postfix ? <div className="input_postfix">{postfix}</div> : null;
  const pre = prefix ? <div className="input_prefix">{prefix}</div> : null;

  const input = (
    <input
      className={(post ? "has_postfix" : "") + (pre ? " has_prefix" : "")}
      aria-invalid={ariaInvalid(error, !!required)}
      ref={ref}
      type="number"
      value={val}
      required={required}
      readOnly={readOnly}
      disabled={disabled}
      autoFocus={autoFocus}
      placeholder={valOrLiteral(placeholder) ?? ph(min, max)}
      min={min}
      max={max}
      step={step}
      onFocus={(e) => setFocus(true)}
      onBlur={(e) => {
        checkErrors();
        setFocus(false);
      }}
      onChange={(e) => {
        const str = e.target.value;
        setVal(str ? +str : "", debounce);
      }}
    />
  );

  return (
    <label>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <ToolTip message={focus && !!error ? error : undefined}>
        {post || pre ? (
          <div className="addon_container">
            {pre}
            {input}
            {post}
          </div>
        ) : (
          input
        )}
      </ToolTip>
    </label>
  );
}
