import React, { useMemo } from "react";
import { RowProps } from "../Row";
import { useEvent } from "../server_hooks";
import { WidgetStructure } from "../RegionState";
import { MarkdownDeclaration, RenderElement } from "../protocol";
import { RenderList } from "../Markdown";
import { Icon } from "./Icon";

type Option = { label?: string; action: string };

const EMPTY_MAP: ReadonlyMap<string, WidgetStructure> = new Map();

type Props = {
  content: MarkdownDeclaration | string;
  action?: Option;
  dismiss?: Option;
  icon?: string;
  variant?: "info" | "alert" | "success" | "error";
} & RowProps;

export function InfoBar({ content, action, dismiss, icon, variant }: Props) {
  const onClick = useEvent<string>("click");

  const msg = useMemo(() => {
    if (typeof content === "string") {
      return content;
    } else {
      const el: RenderElement = { type: "md", data: content };
      return <RenderList content={[el]} widgets={EMPTY_MAP} />;
    }
  }, [content]);

  return (
    <mark className="info_bar">
      <div className="info_bar_msg">
        {icon ? <Icon icon={icon} gap /> : null}
        {msg}
      </div>
      <div className="options">
        {action ? (
          <span role="link" onClick={(e) => onClick(action.action)}>
            {action.label}
          </span>
        ) : null}
        {dismiss ? (
          <span role="link" onClick={(e) => onClick(dismiss.action)}>
            {dismiss.label || <>&#10005;</>}
          </span>
        ) : null}
      </div>
    </mark>
  );
}
