import { Widget } from "./Widget";

type Props = {
  text: string;
};

export function ConfirmWidget(props: Props) {
  return (
    <>
      <p>{props.text}</p>
      <div className="grid dialog">
        <span></span>
        <Widget id="cancel" />
        <Widget id="confirm" />
      </div>
    </>
  );
}
