import React, { useState } from "react";
import { RowProps } from "../Row";
import { useHandleMethodCall, useValidatedVal } from "../server_hooks";
import { Updatable } from "../useUpdatableProps";

import { ToolTip } from "./ToolTip";
import { valOrLiteral, MaybeLabel } from "./MaybeLabel";
import { ariaInvalid } from "./CheckboxGroupWidget";

type Props = {
  label?: string;
  placeholder?: Updatable<string> | string;
  multiple?: boolean;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  debounce?: number;
} & RowProps;

export function EmailWidget(props: Props) {
  const {
    label,
    multiple,
    placeholder,
    disabled,
    autoFocus,
    required,
    readOnly,
    rowHasLabel,
    debounce = 0,
  } = props;
  const [focus, setFocus] = useState(false);
  const { setVal, val, error, checkErrors, ref } = useValidatedVal<string>();

  useHandleMethodCall("focus", () => {
    ref.current?.focus();
    ref.current?.scrollIntoView();
  });

  return (
    <label>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <ToolTip message={focus && !!error ? error : undefined}>
        <input
          aria-invalid={ariaInvalid(error, !!required)}
          type="email"
          ref={ref}
          value={val}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
          autoFocus={autoFocus}
          placeholder={valOrLiteral(placeholder)}
          multiple={multiple}
          onFocus={() => setFocus(true)}
          onBlur={() => {
            checkErrors();
            setFocus(false);
          }}
          onChange={(e) => setVal(e.target.value, debounce)}
        />
      </ToolTip>
    </label>
  );
}
