import React, {
  CSSProperties,
  createContext,
  memo,
  useContext,
  useMemo,
  useState,
} from "react";
import { serializeData } from "../ConsumerManager";
import { RenderList } from "../Markdown";
import { PathNode } from "../PathNode";
import { ClientUpdate, Path, RegionType } from "../protocol";
import { RegionState, WidgetStructure } from "../RegionState";
import { ValInfo } from "../RegionVals";
import { ValContext, WidgetContext } from "../server_hooks";
import { useUpdatable } from "../useUpdatableProps";
import {
  FormComponent,
  FormContext,
  FormContextType,
  OnSubmit,
} from "./FormWidget";
import { Widget } from "./Widget";
import { Icon } from "./Icon";
import { cx } from "./renderValue";
import { inverse } from "./Theme";

const EMPTY_VALS: ReadonlyMap<string, ValInfo> = new Map();

export type RegionStatus = Readonly<{
  type: RegionType;
  parentId: undefined | string;
  updating: boolean;
  latestUpdate: undefined | ClientUpdate;
  /** a map from the regionId to the id passed by the dev and used by the markdown */
  launchedChildren: ReadonlyMap<string, string>;
}>;

// these are placeholders for now, will get more robust when we start supporting
// multiple regions
type RegionProps = {
  region: RegionState;
  isForm?: boolean;
  singletonWidget?: string;
};

type RegionsContext = {
  regions: ReadonlyMap<string, RegionState>;
};

export const RegionsContext = createContext<RegionsContext>(undefined as any);

type Props = {
  id: string;
  isCard?: boolean;
  appearance?: "plain" | "card" | "well" | "sidebar" | "sidebar_inverse";
  isForm?: boolean;
  singletonWidget?: string;
};

export function RegionWidget(props: Props) {
  const {
    id: regionId,
    isCard,
    appearance = isCard ? "card" : "plain",
  } = props;
  const { regions } = useContext(RegionsContext);

  // the region (in regions.get) should always be defined on the first render,
  // however, it may disappear after subsequent renders if we hang around a bit
  // (which probably won't happen, hard to say)
  const region = useMemo(() => regions.get(regionId), [regionId]);
  return (
    <RegionContent
      key={regionId}
      region={region!}
      appearance={appearance}
      isForm={props.isForm}
      singletonWidget={props.singletonWidget}
    />
  );
}

const RegionContent = memo(
  ({
    region,
    singletonWidget,
    appearance = "plain",
    isForm = false,
  }: RegionProps & Props) => {
    const structure = useUpdatable(region.structure);
    const {
      pending,
      val: { cSeq, widgets, body, launchedChildren, coverId },
    } = structure;

    const [lockUntil, setLockUntil] = useState(0);

    const widgetsAndChildren = useMemo(() => {
      const combined = new Map<string, WidgetStructure>(widgets);

      // append the launched children
      launchedChildren.forEach((id: string, regionId: string) => {
        combined.set(id, {
          pathNode: new PathNode(id),
          tag: "Region",
          vals: EMPTY_VALS,
          props: { id: regionId },
          children: null,
        });
      });

      return combined;
    }, [widgets, launchedChildren]);

    // if we are within a form, then use that as our context
    let wrapperForm: null | FormContextType = useContext(FormContext);
    if (wrapperForm?.isInForm) {
      // keep the parent form... however it's region is updated below
    } else {
      wrapperForm = null;
    }

    const memoized = useMemo(() => {
      const handleUpdate = (
        isBlocking: boolean,
        path: ReadonlyArray<string>,
        key: string,
        val: Uint8Array,
        isValid: boolean,
        debounce: number
      ) => {
        const index = region.updateRegion(path, key, val, true, debounce);
        isBlocking && setLockUntil(index);
      };

      const elements = new Set<OnSubmit>();

      return {
        valContext: {
          onUpdate: handleUpdate,
          vals: EMPTY_VALS,
          regionVals: region.vals,
        },
        // if we are within a form, use it to validate, but submit from our region
        // Otherwise, make a new one.
        formContext: wrapperForm
          ? {
              ...wrapperForm,
              // validate is default false... need to use ui.submit explicilty
              onFormSubmit(path: Path, validate: boolean = false) {
                if (validate && wrapperForm) {
                  let valid = true;
                  wrapperForm.elements.forEach((handleSubmit) => {
                    valid = handleSubmit(valid) && valid;
                  });
                  if (!valid) return;
                }
                handleUpdate(
                  true,
                  path,
                  "click",
                  new Uint8Array(serializeData(true)),
                  true,
                  0
                );
              },
            }
          : {
              isInForm: isForm,
              elements,
              // validate is default false... need to use ui.submit explicilty
              onFormSubmit(path: Path, validate: boolean = false) {
                if (validate && isForm) {
                  let valid = true;
                  elements.forEach((handleSubmit) => {
                    valid = handleSubmit(valid) && valid;
                  });
                  if (!valid) return;
                }
                handleUpdate(
                  true,
                  path,
                  "click",
                  new Uint8Array(serializeData(true)),
                  true,
                  0
                );
              },
            },
      };
    }, [region, isForm]);

    const sidebar = widgetsAndChildren.has("__sm_sidebar") ? (
      <Widget id="__sm_sidebar" />
    ) : null;
    const menubar = widgetsAndChildren.has("__sm_menubar") ? (
      <Widget id="__sm_menubar" />
    ) : null;

    let content;
    if (body.length || widgetsAndChildren.size) {
      const children = singletonWidget ? (
        <Widget id={singletonWidget} />
      ) : (
        <>
          {widgetsAndChildren.has("__sm_page_params") ? (
            <Widget id="__sm_page_params" />
          ) : null}
          {widgetsAndChildren.has("__sm_history") ? (
            <Widget id="__sm_history" />
          ) : null}
          {widgetsAndChildren.has("__sm_prevent_exit") ? (
            <Widget id="__sm_prevent_exit" />
          ) : null}
          {widgetsAndChildren.has("__sm_theme") ? (
            <Widget id="__sm_theme" />
          ) : null}
          {menubar}
          {sidebar || menubar ? (
            <div className="sidebar_wrapper">
              {sidebar}
              <div className="sidebar_content">
                <RenderList content={body} widgets={widgetsAndChildren} />
              </div>
            </div>
          ) : (
            <RenderList content={body} widgets={widgetsAndChildren} />
          )}
        </>
      );

      // TODO: put region busy back... in a form that doesn't shift UI
      content = (
        <ValContext.Provider value={memoized.valContext}>
          <WidgetContext.Provider
            value={{
              widgets: widgetsAndChildren,
              locked: cSeq < lockUntil,
            }}
          >
            <FormContext.Provider value={memoized.formContext}>
              {children}
              {/* {pending ? <p aria-busy /> : null} */}
            </FormContext.Provider>
          </WidgetContext.Provider>
        </ValContext.Provider>
      );
    } else {
      content = <p style={{ height: "100%" }} aria-busy />;
    }

    if (isForm && !wrapperForm) {
      content = <FormComponent>{content}</FormComponent>;
    }

    const hide = coverId != null ? { display: "hidden" } : undefined;

    const [sideBarOpen, setSideBarOpen] = useState(false);

    function render() {
      switch (appearance) {
        case "sidebar":
        case "sidebar_inverse":
          return (
            <>
              <Icon
                icon="chevrons-right"
                className="sidebar_open"
                onClick={(e) => setSideBarOpen(true)}
              />
              <aside
                className={cx(
                  "region_wrapper",
                  "sidebar",
                  sideBarOpen ? "open" : ""
                )}
                {...inverse(appearance === "sidebar_inverse")}
              >
                <Icon
                  icon="chevrons-left"
                  className="sidebar_close"
                  onClick={(e) => setSideBarOpen(false)}
                />
                {content}
              </aside>
            </>
          );
        case "card":
          return (
            <div className="region_wrapper" style={hide}>
              <article>{content}</article>
            </div>
          );
        case "well":
          return (
            <div className="region_wrapper" style={hide}>
              <div className="container-well">{content}</div>
            </div>
          );
        default:
          return (
            <div
              className={cx(
                "region_wrapper",
                sidebar && "has_sidebar",
                menubar && "has_menubar"
              )}
            >
              {content}
            </div>
          );
      }
    }

    return (
      <>
        {coverId ? <RegionWidget id={coverId} /> : null}
        {render()}
      </>
    );
  }
);
