import { RowProps } from "../Row";
import { Doughnut } from "react-chartjs-2";
import { ImgSize } from "./ImgSize";
import { darken } from "color2k";
import React from "react";
import { cat_color } from "./Colors";

import {
  ChartData,
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { MaybeLabel } from "./MaybeLabel";

ChartJS.register(ArcElement, Tooltip, Legend);

type PieProps = {
  label?: string;
  caption?: string;
  size?: ImgSize;
  data: ChartData<"doughnut">;
} & RowProps;

export function PieChartWidget({
  data,
  label,
  caption,
  size = "m",
  rowHasLabel,
  inRow,
}: PieProps) {
  const options: any = {
    maintainAspectRatio: false,
    animation: false,
    borderWidth: 1,

    plugins: {
      legend: {
        labels: {
          boxWidth: 10,
          filter: (legend: { text?: string }) => !!legend.text,
        },
        maxHeight: 24, // prevent wrapped legend height from squeeshing the pie
        position: "bottom",
      },
    },
  };

  data = {
    ...data,
    datasets: data.datasets.map((ds) => {
      return {
        ...ds,
        backgroundColor:
          ds.backgroundColor || ds.data.map((_, i) => cat_color(i)),
        borderColor:
          ds.borderColor || ds.data.map((_, i) => darken(cat_color(i), 0.2)),
      };
    }),
  };

  return (
    <figure>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <div className={"ui_chart ui_chart_pie " + size}>
        <Doughnut data={data} options={options} />
      </div>
      {caption && <figcaption>{caption}</figcaption>}
    </figure>
  );
}
