import { Renderable } from "../Renderable";
import { RowProps } from "../Row";
import { cx, renderValue } from "./renderValue";
import React from "react";
import { Icon } from "./Icon";
import {
  compileNavigationTarget,
  navigateTo,
  NavigationTarget,
  useCurrentRoute,
} from "./History";

type Option = {
  label?: Renderable;
  icon?: string;
  disabled?: boolean;
  indent?: number;
  nav: NavigationTarget;
};

type Props = {
  id: string;
  options: Option[];
  appearance: "nav" | "tabs";
} & RowProps;

export function NavBarWidget({ id, options, appearance = "nav" }: Props) {
  let ca = "";
  switch (appearance) {
    case "tabs":
      ca = " tabs";
      break;
  }

  const large = options.some((o) => o.label != null) ? undefined : "large_icon";

  const currentRoute = useCurrentRoute();

  return (
    <figure className={cx("nav_widget ", ca)} key={id}>
      <nav>
        <ul>
          {options.map(
            ({ label, disabled = false, icon, indent = 0, nav }, index) => {
              const target = compileNavigationTarget(nav);

              const isSelected = target === currentRoute;

              return (
                <li
                  key={index}
                  className={cx(
                    icon && "with_icon",
                    !!indent && `indent-${indent}`,
                    isSelected && "selected"
                  )}
                  {...(disabled
                    ? { "aria-disabled": true }
                    : isSelected
                    ? { "aria-selected": true }
                    : {
                        onClick: () => navigateTo(target),
                      })}
                >
                  {icon && <Icon icon={icon} className={large} gap={label} />}
                  {renderValue(label)}
                </li>
              );
            }
          )}
        </ul>
      </nav>
    </figure>
  );
}
