import {
  useControlledVal,
  useEvent,
  useHandleMethodCall,
} from "../server_hooks";
import { type ImgSize } from "./ImgSize";
import { type RowProps } from "../Row";
import React, { useState } from "react";
import { MaybeLabel } from "./MaybeLabel";

export type AudioWidgetProps = {
  id: string;
  label?: string;
  caption?: string;
  size?: ImgSize;
  src: string;
} & RowProps;

export function AudioWidget(props: AudioWidgetProps) {
  const val = useControlledVal<number>("val");
  const played = useControlledVal<number>("played");
  const playing = useControlledVal<boolean>("playing");
  const onPause = useEvent("pause");
  const onPlay = useEvent("play");

  const [lastFlush, setLastFlush] = useState(-100);

  const ref = React.useRef<HTMLAudioElement>(null);

  useHandleMethodCall("seekTo", (time: number) => {
    if (ref.current) {
      ref.current.currentTime = time;
    }
  });

  useHandleMethodCall("play", () => ref.current?.play());
  useHandleMethodCall("pause", () => ref.current?.pause());

  const { id, src, label, caption, rowHasLabel, size } = props;

  return (
    <figure>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <audio
        ref={ref}
        key={id}
        src={src}
        controls
        className={size}
        onTimeUpdate={(e) => {
          const v = e.target as HTMLVideoElement;
          const t = v.currentTime;
          const debounce = t - lastFlush > 0.5 ? 0 : 500;
          if (!debounce) setLastFlush(t);
          val.setVal(t, true, debounce);
          played.setVal(t, true, debounce);
        }}
        onPlay={() => {
          playing.setVal(true);
          onPlay(true);
        }}
        onPause={() => {
          playing.setVal(false);
          onPause(true);
        }}
      />
      {caption ? <caption>{caption}</caption> : null}
    </figure>
  );
}
