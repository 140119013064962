import React, { useEffect } from "react";
import { useControlledVal } from "../server_hooks";

export function PageParams() {
  const { val, setVal } = useControlledVal<{ url: string }>();

  useEffect(() => {
    function update() {
      setVal({ url: document.location.href });
    }
    window.addEventListener("popstate", update);
    return () => window.removeEventListener("popstate", update);
  }, []);

  return <></>;
}
