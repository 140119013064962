import React, { ReactNode } from "react";
import { isEqualRenderable, Renderable } from "../Renderable";
import { RowProps } from "../Row";
import { useControlledVal } from "../server_hooks";
import { Updatable } from "../useUpdatableProps";

import { renderValue } from "./renderValue";
import { MaybeLabel } from "./MaybeLabel";

type Option = Renderable | { value: Renderable; label: Renderable };

type Props = {
  id: string;
  label?: string;
  options: Option[];
  required?: boolean;
  placeholder?: string;
} & RowProps;

function opt(i: number): string {
  return "o" + i;
}

let UNIQUE = 100;

function valueOf(option: Option): Renderable {
  return (option as any).value ?? option;
}

function labelOf(option: Option): Renderable {
  return (option as any).label ?? option;
}

export function OneOfWidget(props: Props) {
  const {
    id,
    label,
    options,
    required,
    placeholder = "Select...",
    rowHasLabel,
    inRow = false,
  } = props;
  const val = useControlledVal<Renderable>();

  const name = "x" + UNIQUE++;

  if (!inRow && options.length < 6) {
    return (
      <fieldset key={id}>
        <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
        {options.map((c) => {
          const v = valueOf(c);
          return (
            <label key={v.toString()}>
              <input
                name={name}
                checked={isEqualRenderable(v, val.val)}
                type="radio"
                onChange={(e) => {
                  if (e.target.checked) val.setVal(v);
                }}
              />
              {renderValue(labelOf(c))}
            </label>
          );
        })}
      </fieldset>
    );
  } else {
    const mapped = Object.fromEntries(
      options.map((c, i) => [opt(i), valueOf(c)])
    );
    return (
      <label>
        <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
        <select
          style={{ marginTop: 0 }}
          value={opt(
            options.findIndex((c) => isEqualRenderable(valueOf(c), val.val))
          )}
          onChange={(e) => {
            const v = mapped[e.target.value];
            if (v === undefined) return;
            val.setVal(v);
          }}
        >
          <option key="-1" value="-1">
            {placeholder}
          </option>
          {options.map((c, i) => (
            <option key={opt(i)} value={opt(i)}>
              {renderValue(labelOf(c))}
            </option>
          ))}
        </select>
      </label>
    );
  }
}
