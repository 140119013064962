import { RowProps } from "../Row";
import React from "react";
import { Icon } from "./Icon";
import { MaybeLabel } from "./MaybeLabel";
import { cx } from "./renderValue";

type Props = {
  id: string;
  label?: string;
  href: string;
  target?: string;
  download?: boolean | string;
  icon?: string;
  appearance?: "link" | "button" | "button_outline" | "button_secondary";
} & RowProps;

export const ZW_SPACE = "\u{200B}";

// TODO: icon, style, disabled
export function LinkWidget({
  id,
  label,
  icon,
  href,
  target = "_parent",
  download,
  appearance = "link",
  rowHasLabel,
  inRow,
}: Props) {
  const logo = icon ? <Icon icon={icon} gap={label} /> : null;

  const link = (
    <a
      role={appearance.startsWith("button") ? "button" : undefined}
      className={cx(
        "link",
        appearance === "button_outline" && "outline",
        appearance === "button_secondary" && "secondary"
      )}
      href={href}
      target={target}
      download={download}
      data-tooltip={
        label || icon ? null : "Please supply a label or icon for this link"
      }
    >
      {logo}
      {label ? label : icon ? ZW_SPACE : `⚠️ [${id}]`}
    </a>
  );

  return inRow ? (
    <label>
      <MaybeLabel rowHasLabel={rowHasLabel} />
      {link}
    </label>
  ) : (
    link
  );
}
