import { RegionWidget } from "./widgets/RegionWidget";

type Props = {
  alertId: null | string;
};

export function AlertWrapper({ alertId }: Props) {
  if (!alertId) return null;

  return (
    <div
      className="modal_wrapper"
      style={{
        zIndex: 3000,
      }}
    >
      <article className="alert">
        <RegionWidget id={alertId} singletonWidget="alert" />
      </article>
    </div>
  );
}
