import { PagedData } from "@skymass/skymass/dist/widgets/TableWidget.mjs";
import { startOfWeek, getDay, format } from "date-fns";
import { enUS } from "date-fns/locale";
import React from "react";
import {
  Calendar,
  Event,
  dateFnsLocalizer,
  CalendarProps,
  DateLocalizer,
} from "react-big-calendar";
import withDragAndDrop, {
  EventInteractionArgs,
} from "react-big-calendar/lib/addons/dragAndDrop";
import { navigate } from "react-big-calendar/lib/utils/constants.js";
import { RowProps } from "../Row";
import { useControlledVal, useEvent, useSetVal } from "../server_hooks";
import { Updatable } from "../useUpdatableProps";
import { cx } from "./renderValue";
import { Icon } from "./Icon";

type EventType = {
  id: string | number;
  title: string;
  start: Date;
  end: Date;
  allDay?: boolean;
  color?: string;
  locked?: boolean;
};

type CalView = "day" | "week" | "month";
type Props = {
  events: Updatable<EventType[] | PagedData<EventType>>;
  total: undefined | Updatable<undefined | number>;
  label?: string;
  defaultDate?: Date;
  views?: CalView[];
  defaultView?: CalView;
  timeslots: number;
  step: number;
  size: "s" | "m" | "l" | "stretch";
} & RowProps;

const localizer = dateFnsLocalizer({
  startOfWeek,
  getDay,
  format,
  locales: { enUS },
});

//@ts-ignore
const DnDCalendar = withDragAndDrop(Calendar);

type EventChange =
  | { type: "replace"; events: EventType[] }
  | { type: "resize"; interaction: EventInteractionArgs<EventType> }
  | { type: "move"; interaction: EventInteractionArgs<EventType> };

export function CalendarWidget(props: Props) {
  const onSelect = useEvent<Event>("select");
  const onSelectSlot = useEvent("select_slot");
  const onResize = useEvent<Event>("resize");
  const onMove = useEvent<Event>("move");
  const setSelection = useSetVal<Event>("selection");
  const range = useControlledVal<{ from: Date; to: Date }>("range");
  const wrapperElement = React.useRef<HTMLDivElement>(null);

  // This is a great use case for immer, but unfortunately,
  // immer clashes with react-big-calendar which tries to modify a frozen object on object move.
  const [events, dispatchEventChange] = React.useReducer(
    (currentEvents: EventType[], action: EventChange) => {
      if (action.type === "replace") {
        return action.events;
      } else if (action.type === "resize" || action.type === "move") {
        const {
          start,
          end,
          event: { id },
        } = action.interaction;

        return currentEvents.map((e) =>
          e.id === id ? { ...e, start, end } : e
        ) as EventType[];
      } else {
        throw new Error(`Invalid action type ${action}`);
      }
    },
    [],
    () => []
  );

  const {
    events: rawData,
    defaultDate = new Date(),
    views = ["day", "week", "month"],
    defaultView = "week",
    timeslots = 2,
    size = "m",
    step = 30,
    label: title,
  } = props;

  React.useEffect(() => {
    let newEvents: EventType[] = [];
    if (!rawData.val) {
      newEvents = [];
    } else if (Array.isArray(rawData.val)) {
      newEvents = rawData.val;
    } else {
      newEvents = rawData.val.data;
    }
    dispatchEventChange({ type: "replace", events: newEvents });
  }, [rawData.val]);

  const { components } = React.useMemo<Pick<CalendarProps, "components">>(
    () => ({
      components: {
        toolbar: ((props: CalendarToolbarProps) => (
          <CustomCalendarToolbar
            {...props}
            title={title}
            busy={rawData.pending && rawData.val ? true : undefined}
          />
        )) as any,
      },
    }),
    [title, rawData.pending, !!rawData.val]
  );

  function scrollToCurrentTime() {
    // needs a delay to allow the calendar to render
    queueMicrotask(() => {
      wrapperElement.current
        ?.querySelector(".rbc-current-time-indicator")
        ?.scrollIntoView({ block: "center" });
    });
  }

  React.useEffect(() => {
    scrollToCurrentTime();
  }, []);

  return (
    <div className={cx("ui_calendar_wrapper", size)} ref={wrapperElement}>
      <DnDCalendar
        onRangeChange={(newRange) => {
          const from =
            newRange instanceof Array ? newRange.at(0)! : newRange.start;

          const to =
            newRange instanceof Array ? newRange.at(-1)! : newRange.end;

          scrollToCurrentTime();

          // TODO: expand this to at least a week? and only update if changed.
          range.setVal({ from, to });
        }}
        selectable
        onSelectSlot={(e) => {
          onSelectSlot(e);
        }}
        onSelectEvent={(e) => {
          setSelection(e);
          onSelect(e);
        }}
        onEventResize={(interaction) => {
          dispatchEventChange({
            type: "resize",
            interaction: interaction as any,
          });
          //@ts-ignore
          onResize(interaction);
        }}
        onEventDrop={(interaction) => {
          dispatchEventChange({
            type: "move",
            interaction: interaction as any,
          });
          //@ts-ignore
          onMove(interaction);
        }}
        components={components}
        defaultDate={defaultDate}
        defaultView={defaultView}
        timeslots={timeslots}
        events={events}
        localizer={localizer}
        showMultiDayTimes
        formats={calendarFormats}
        step={step}
        views={views}
        draggableAccessor={dragAccessorHandler}
        resizableAccessor={dragAccessorHandler}
        // @ts-ignore
        eventPropGetter={(event: EventType) => {
          if (event.color) {
            return {
              style: { backgroundColor: event.color, borderColor: event.color },
            };
          }
          return {};
        }}
        enableAutoScroll
      />
    </div>
  );
}

interface CalendarToolbarProps {
  localizer: DateLocalizer;
  label: string;
  title: string | undefined;
  busy: true | undefined;
  views: CalView[];
  view: CalView;
  onNavigate: (action: string) => void;
  onView: (view: CalView) => void;
}

const CustomCalendarToolbar: React.FC<CalendarToolbarProps> = (props) => {
  const {
    onNavigate,
    localizer: { messages },
    label,
    title,
    busy,
  } = props;

  return (
    <div className="rbc-toolbar">
      <label className="lbl">{title}</label>
      <label className="lbl title">{label}</label>
      <div className="pagination">
        <div className="busy" aria-busy={busy} />
        <button type="button" onClick={() => onNavigate(navigate.TODAY)}>
          {messages.today}
        </button>
        <Icon
          className="clickable"
          icon="chevron-left"
          onClick={() => onNavigate(navigate.PREVIOUS)}
        />
        <Icon
          className="clickable"
          icon="chevron-right"
          onClick={() => onNavigate(navigate.NEXT)}
        />
        {props.views.length > 1 && (
          <span className="rbc-btn-group">
            {props.views.map((viewName) => (
              <button
                type="button"
                key={viewName}
                className={cx(props.view === viewName ? "rbc-active" : null)}
                onClick={() => props.onView(viewName)}
              >
                {messages[viewName]}
              </button>
            ))}
          </span>
        )}
      </div>
    </div>
  );
};

// the code below includes the n-dash unicode character - &ndash;
// recommendation: https://site.uit.no/english/punctuation/hyphen/#:~:text=The%20en%2Ddash%20is%20a,any%20kind%20of%20ranges%2C%20typically.
const calendarFormats: CalendarProps["formats"] = {
  dayHeaderFormat: (date, culture, localizer) => {
    return localizer!.format(date, `EEE MMM dd`, culture);
  },
  dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
    if (start.getMonth() === end.getMonth()) {
      return (
        localizer!.format(start, `MMM dd`, culture) +
        " – " +
        localizer!.format(end, `dd`, culture)
      );
    } else {
      return (
        localizer!.format(start, `MMM dd`, culture) +
        " – " +
        localizer!.format(end, `MMM dd`, culture)
      );
    }
  },
  monthHeaderFormat: (date, culture, localizer) => {
    return localizer!.format(date, `MMM yy`, culture);
  },
  eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
    const inSameHalf =
      (start.getHours() < 12 && end.getHours() < 12) ||
      (start.getHours() >= 12 && end.getHours() >= 12);

    if (inSameHalf) {
      return (
        localizer!.format(start, `h:mm`, culture) +
        " – " +
        localizer!.format(end, `h:mma`, culture)
      );
    }

    return (
      localizer!.format(start, `h:mma`, culture) +
      " – " +
      localizer!.format(end, `h:mma`, culture)
    );
  },
};

function dragAccessorHandler(event: object) {
  return !(event as EventType).locked;
}
