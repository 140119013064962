import {
  useAction,
  useControlledVal,
  useEvent,
  useSetVal,
} from "../server_hooks";
import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { Updatable } from "../useUpdatableProps";
import {
  renderValue,
  type RenderFormats,
  StackBarSegment,
  cx,
  ZW_SPACE,
  debounce,
} from "./renderValue";
import { Renderable } from "../Renderable";
import { serializeData } from "../ConsumerManager";
import { RowProps } from "../Row";
import {
  TableComponents,
  TableVirtuoso,
  TableVirtuosoHandle,
} from "react-virtuoso";
import { Icon } from "./Icon";
import { FloatingPortal } from "@floating-ui/react";
import { MaybeLabel } from "./MaybeLabel";
import {
  MenuItem,
  MenuItems,
  MENU_SEPARATOR,
  useSkyMassContextMenu,
  useSkyMassPopupMenu,
} from "./PopupMenuHandler";

type ColAdj = {
  col: number;
  shift: boolean;
  x: number;
  dx: number;
};

type ColFormats =
  | RenderFormats
  | "hover_action"
  | "action"
  | "multiline"
  | "paragraph"
  | "image"
  | "icon_label"
  | "avatar"
  | "profile";

type MetaCol = {
  search: false;
  sort: false;
  hidden: false;
  label: "";
};

type ContentSize = "xs" | "s" | "m" | "l" | "xl";

type Columns = {
  "*"?: {
    search?: boolean;
    sort?: boolean;
    hidden?: boolean;
    format?: RenderFormats;
    size?: ContentSize;
  };
} & {
  __sm_multiselect?: MetaCol;
} & {
  __sm_actions?: MetaCol;
} & {
  [key: string]: {
    isId?: boolean;
    search?: boolean;
    sort?: boolean;
    label?: string;
    type?: string;
    hidden?: boolean;
    format?: ColFormats;
    size?: ContentSize;
    expand?: boolean;
  };
};

type Action = string | { action: string; label?: Renderable; icon?: string };
type Actions = Action[];

type PagedTableData = {
  type: "paged";
  offset: number;
  pageSize: number;
  data: object[];
  hasMore?: boolean;
};

type CursorTableData = {
  type: "cursor";
  data: object[];
  prevCursor?: object;
  nextCursor?: object;
  pageLabel?: string;
};

type TableData = object[] | PagedTableData | CursorTableData;

type Props = {
  rows: Updatable<TableData>;
  total: undefined | Updatable<undefined | number>;
  literalData: boolean; // literal data can be sorted / filtered locally
  label?: string;
  loading?: string; // loading message
  empty?: string; // empty message
  search?: string; // search placeholder
  size?: "s" | "m" | "l" | "rows" | "stretch";
  multiSelect?: boolean;
  select?: boolean | "single" | "multi";
  columns: Columns;
  actions?: Actions;
  toolbar?: Actions;
} & RowProps;

type SelectedRows = { [key: string]: {} };

const EMPTY_ARRAY = [];
// TODO: make this per col, eg checkbox
const MIN_COL_WIDTH = 30; // px
const DEFAULT_MIN_COLUMN_WIDTH = 100; // px

const CATCH_ALL = "*";
const SM_ACTIONS = "__sm_actions";
const SM_MULTISELECT = "__sm_multiselect";
const SPECIAL_COLS = [CATCH_ALL, SM_ACTIONS, SM_MULTISELECT];
const ACTION_FORMATS = ["action", "hover_action"];

const EMPTY_DATA = Array(50).fill(null);

function sum(a: number[]) {
  return a.reduce((a, b) => a + b, 0);
}

function px(x: number) {
  return x + "px";
}

function pcnt(x: number) {
  return (100 * x).toFixed(2) + "%";
}

function numfmt(n: number) {
  return new Intl.NumberFormat().format(n);
}

function clip(value: number, min: number, max: number) {
  return value > min ? Math.min(value, max) : Math.max(value, min);
}

function doSearch(value, needle) {
  if (!value) return false;

  if (Array.isArray(value)) return value.some((val) => doSearch(val, needle));

  const t = typeof value;

  if (t === "string") return value.toLowerCase().includes(needle);
  if (t === "number") return value.toString().includes(needle);

  if (value instanceof Date)
    return value.toString().toLowerCase().includes(needle);

  if (value instanceof Object)
    return Object.values(value).some((val) => doSearch(val, needle));

  return false;
}

interface TableVirtuosoContext {
  tableRef: RefObject<HTMLTableElement>;
  colWidths: number[] | null;
  toggleSelection: (key: string, rowIndex: number, add: boolean) => void;
  selectedRows: {};
  rowActions: Actions | Action | undefined;
  onRowAction: (val: { action: string; row: {} }, action: string) => void;
  data: {}[];
  shownCols: string[];
  columns: Columns;
  tableMinWidth: number | undefined;
  // used in the table row
  padded: any[];
  clearSelection: () => void;
  onRowClick: (val: { col: string; row: {} }) => void;
  multiSelect: boolean;
  rowId: (row: Record<string, unknown>) => string;
  computeWidths: (tr: HTMLTableRowElement) => void;
  select: boolean | "single" | "multi";
}

const TableRow: Required<TableComponents<TableVirtuosoContext>>["TableRow"] = (
  props
) => {
  const context = props.context!;
  const index = props["data-index"];
  const row = context.padded[index];

  const emptyRowTap = useTap(() => context.clearSelection());

  const rowTap = useTap((e) => {
    const tgt = e.target as HTMLElement;
    const td = tgt.closest("td");
    if (td?.dataset.col) {
      context.onRowClick({ row, col: td.dataset.col });
    }
    context.toggleSelection(key, index, !isSelected);
  });

  if (!row) {
    return (
      <tr
        className={index % 2 ? "even" : undefined}
        {...props}
        {...(context.multiSelect ? {} : emptyRowTap)}
      ></tr>
    );
  }
  const key = context.rowId(row);
  const isSelected = !!context.selectedRows[key];
  if (isSelected) {
    if (props.style) {
      props.style.backgroundColor = "var(--form-element-focus-color)";
    }
  }

  return (
    <tr
      ref={index || context.colWidths ? undefined : context.computeWidths}
      key={key}
      data-key={key}
      className={index % 2 ? "even" : undefined}
      onContextMenu={(e) => {
        if (e.altKey) {
          const tgt = e.target as HTMLElement;
          const td = tgt.closest("td");
          td && window.getSelection()?.selectAllChildren(td);
        }
      }}
      {...rowTap}
      {...props}
    />
  );
};

const VirtuosoCustomComponents: Pick<
  TableComponents<TableVirtuosoContext>,
  "Table" | "TableBody" | "TableRow"
> = {
  Table: ({ context, ...props }) => (
    <table
      {...props}
      className={context?.colWidths ? "ui_table fixed" : "ui_table auto"}
      {...(context?.tableMinWidth
        ? { style: { minWidth: context.tableMinWidth } }
        : {})}
      ref={context?.tableRef}
    />
  ),
  TableBody: React.forwardRef(({ context, ...props }, ref) => {
    const contextMenuProps = useTableContextMenu(context!);
    return <tbody {...props} {...contextMenuProps} ref={ref} />;
  }),

  TableRow,
};

export function TableWidget(props: Props) {
  // const retainSelection = true;

  const setSerializedSelection = useSetVal<Uint8Array>("selection");
  const sort = useControlledVal<string>("sort");
  const srch = useControlledVal<string>("search");
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const page = useControlledVal<number>("page");
  const cursorControlledVal = useControlledVal<unknown>("cursor");
  const onSelected = useEvent<boolean>("select");
  const onUnselected = useEvent<boolean>("unselect");
  const onRowAction = useAction<{ action: string; row: {} }>("row_action");
  const onRowClick = useEvent<{ col: string; row: {} }>("row_click");
  const onToolbarAction = useEvent<string>("toolbar_action");

  // whether we need to pad with filler rows
  const [needsPad, setNeedsPad] = useState(true);

  const [selectedRows, setSelectedRows] = useState<SelectedRows>({});

  const selectedKeys = useMemo(() => Object.keys(selectedRows), [selectedRows]);
  const hasSelection = selectedKeys.length > 0;

  const [hasSelectedAll, setHasSelectedAll] = useState(false);

  const {
    empty = "No results",
    loading = "Loading…",
    label,
    search = "Search…",
    select = "single",
    multiSelect = select === "multi",
    rows: rawData,
    size = "m",
    literalData,
    actions,
    toolbar,
  } = props;

  let columns = props.columns;

  let pageOffset: undefined | number;
  let cursorData: CursorTableData | null = null;
  let pageSize = 0;
  let hasMore = false;
  let totalCount: undefined | Updatable<number>;

  let rows: object[];
  if (!rawData.val) {
    rows = [];
    totalCount = { pending: false, val: 0 };
  } else if (Array.isArray(rawData.val)) {
    rows = rawData.val;
    totalCount = { pending: false, val: rows.length };
  } else if (rawData.val.type === "paged") {
    const paged = rawData.val;
    rows = paged.data;
    pageOffset = paged.offset;
    pageSize = paged.pageSize;

    const upTo = pageOffset + rows.length;

    if (rows.length < pageSize) {
      totalCount = { pending: false, val: upTo };
    } else {
      if (props.total?.val != null) {
        totalCount = props.total as Updatable<number>;
        hasMore = upTo < totalCount.val!;
      } else {
        // if we do not know if there's more, default to true
        hasMore = paged.hasMore ?? true;
      }
    }
  } else if (rawData.val.type === "cursor") {
    rows = rawData.val.data;
    cursorData = rawData.val;
  } else {
    throw new Error("unknown table data type");
  }

  function colLabel(col) {
    const lbl = columns?.[col]?.label ?? col;
    if (!lbl) return null;

    if (colIsSortable(col)) {
      const dir = col === sort.val ? "+" : sort.val === "-" + col ? "-" : "";
      switch (dir) {
        case "+":
          return <span className="col_sort_asc">{lbl}+</span>;
        case "-":
          return <span className="col_sort_desc">{lbl}-</span>;
        default:
          return <span className="col_sort_asc">{lbl}&nbsp;</span>;
      }
    } else {
      return lbl;
    }
  }

  function colIsSortable(col) {
    return columns?.[col]?.sort ?? columns?.[CATCH_ALL]?.sort ?? true;
  }

  function colFormat(col: string): ColFormats | undefined {
    return columns?.[col]?.format ?? columns?.[CATCH_ALL]?.format;
  }

  function sortFilter(rows: {}[] = [], sort, needle = "", searchable) {
    needle = needle.trim().toLowerCase();
    // TODO: multiple search terms
    rows = needle
      ? rows.filter((row) =>
          searchable.some((col) => doSearch(row[col], needle))
        )
      : rows;

    if (!sort) return rows;

    const [dir, col] = sort.startsWith("-")
      ? [-1, sort.substring(1)]
      : [1, sort];

    const sortFn = sortFnForFormat(colFormat(col));

    return rows.slice().sort((a, b) => sortFn(a[col], b[col]) * dir);
  }

  function colIsNarrow(col) {
    switch (col) {
      case SM_MULTISELECT:
      case SM_ACTIONS:
        return true;
    }
    switch (colFormat(col)) {
      case "image":
      case "avatar":
      case "icon_label":
      case "profile":
        return true;
    }
    return false;
  }

  function colIsVisible(col) {
    // if col is defined, default hidden to false
    return !(columns?.[col]
      ? columns[col].hidden
      : columns?.[CATCH_ALL]?.hidden ?? false);
  }

  function colIsSearchable(col) {
    return (
      columns?.[col]?.search ?? columns?.[CATCH_ALL]?.search ?? literalData
    );
  }

  function colIsId(col) {
    return columns?.[col]?.isId;
  }

  // update selected and backend selection sharedVar without firing select/unselect events
  function _updateSelection(selection: SelectedRows) {
    setSerializedSelection(
      new Uint8Array(serializeData(Object.values(selection)))
    );
    setSelectedRows(selection);
  }

  // manipulate selected + fire select/unselect events.  These are meant to be used for UI interactions
  function clearSelection() {
    setHasSelectedAll(false);
    if (hasSelection) {
      _updateSelection({});
      onUnselected(true);
    }
  }

  function addSelection(key: string, row: {}) {
    if (!select) return;
    if (selectedRows[key]) return;
    if (multiSelect) {
      _updateSelection({ ...selectedRows, [key]: row });
    } else {
      _updateSelection({ [key]: row });
      // trigger unselect on previous
      const prev = Object.values(selectedRows).shift();
      if (prev) {
        onUnselected(true);
      }
    }

    onSelected(true);
  }

  function removeSelection(key: string) {
    const row = selectedRows[key];
    if (!row) return;
    delete selectedRows[key];
    _updateSelection({ ...selectedRows });
    onUnselected(true);
  }

  function toggleSelection(key: string, rowIndex: number, add: boolean) {
    setHasSelectedAll(false);
    if (add) {
      addSelection(key, data[rowIndex]);
    } else {
      removeSelection(key);
    }
  }

  const virtuosoRef = useRef<TableVirtuosoHandle>(null);
  const figureRef = useRef<HTMLElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);

  function updateSearch(value) {
    setSearchFieldValue(value);
    srch.setVal(value.trim(), true, literalData ? 100 : 500);
    virtuosoRef.current?.scrollToIndex(0);

    // this should not be necessary, but only remove once
    // clients have updated.
    page.setVal(0, true, 500);
  }

  function updateSort(col) {
    const newSort = col === sort.val ? "-" + col : col;
    sort.setVal(newSort);
    virtuosoRef.current?.scrollToIndex(0);

    // this should not be necessary, but only remove once
    // clients have updated.
    page.setVal(0);
  }

  function renderCell(row: object, col: string, isSelected: boolean, actions) {
    switch (col) {
      case SM_MULTISELECT:
        return (
          <td key={col} className="narrow">
            <input type="checkbox" readOnly checked={isSelected} />
          </td>
        );
      case SM_ACTIONS:
        // right align is last column and no label
        return (
          <td key={col} className="narrow">
            <div
              className={
                colLabel(col)
                  ? "actions hover_action desktop"
                  : "actions hover_action desktop actions_end"
              }
            >
              {renderActions(actions, row)}
            </div>
            <div className="mobile actions_end">
              <div
                {...useSkyMassPopupMenu(() =>
                  tableActionsToMenuActions(
                    actions,
                    tableContext.onRowAction,
                    row
                  )
                )}
                className="action_menu_button"
              >
                <Icon icon="MoreVertical" />
              </div>
            </div>
          </td>
        );
    }

    const val = row[col];

    const fmt = colFormat(col);

    switch (fmt) {
      case "hover_action":
        return (
          <td key={col}>
            <div className="actions hover_action">
              {val == null ? ZW_SPACE : renderActions(val, row)}
            </div>
          </td>
        );
      case "action":
        return (
          <td key={col}>
            <div className="actions">
              {val == null ? ZW_SPACE : renderActions(val, row)}
            </div>
          </td>
        );
      default:
        return (
          <td key={col} data-col={col} data-fmt={fmt}>
            {renderValue(val, fmt, col) ?? ZW_SPACE}
          </td>
        );
    }
  }

  function _renderAction(val: Action, row: {}) {
    const { action, label, icon } = normalizeAction(val);

    return (
      <span
        role="link"
        className="with_icon"
        key={action}
        onClick={(e) => {
          e.stopPropagation();
          onRowAction({ action, row }, action);
        }}
      >
        {icon ? <Icon icon={icon} gap={label} /> : null}
        {label ?? ZW_SPACE}
      </span>
    );
  }

  function renderActions(actions, row) {
    return Array.isArray(actions)
      ? actions.map((a) => _renderAction(a, row))
      : _renderAction(actions, row);
  }

  let data: {}[];
  let emptyMsg;
  let emptySrchMsg;
  let width = 1;
  let rowIdKeys;
  let rowId!: (row: Record<string, unknown>) => string;
  let cols;
  let shownCols;
  let currPage: number;

  if (multiSelect) {
    columns = {
      __sm_multiselect: {
        label: "",
        hidden: false,
        sort: false,
        search: false,
      },
      ...columns,
    };
  }
  if (actions) {
    columns = {
      ...columns,
      __sm_actions: {
        label: "",
        hidden: false,
        sort: false,
        search: false,
      },
    };
  }

  // clear out selection if select mode becomes false
  useEffect(() => {
    if (select) return;
    if (hasSelection) {
      _updateSelection({});
    }
  }, [select]);

  if (rawData.pending && !rows.length) {
    // async loading initial data

    cols = Object.keys(columns || {}).filter((col) => col !== CATCH_ALL);
    shownCols = cols.filter(colIsVisible);

    width = shownCols.length || 1;

    emptyMsg = (
      <th aria-busy colSpan={width}>
        {loading}
      </th>
    );

    data = EMPTY_ARRAY;
  } else if (!rows.length) {
    // no rows

    // clear out selection, but don't fire events
    if (hasSelection) {
      _updateSelection({});
    }
    cols = Object.keys(columns || {}).filter((col) => col !== CATCH_ALL);
    shownCols = cols.filter(colIsVisible);

    width = shownCols.length || 1;

    // TODO: handle "search" case?
    // if headers are rendered, don't re-hide them?

    emptyMsg = (
      <th colSpan={width}>
        {empty}
        {cursorControlledVal.val || page.val ? (
          <>
            {" "}
            <span
              role="link"
              onClick={(e) => {
                e.stopPropagation();
                cursorControlledVal.setVal(null);
                page.setVal(0);
              }}
            >
              Reset pagination
            </span>
          </>
        ) : null}
      </th>
    );

    data = EMPTY_ARRAY;
    rowId = (row) => "N/A";
  } else {
    // we have data!

    // list of columns based on columns prop and first few rows of data
    // the order of columns is determined by the key order of columns prop and then the rows
    // multiselect / actions always need to go at the begin/end
    cols = [
      ...new Set([
        ...(multiSelect ? [SM_MULTISELECT] : EMPTY_ARRAY),
        ...Object.keys(columns || {}).filter(
          (col) => !SPECIAL_COLS.includes(col)
        ),
        ...Object.keys(Object.assign({}, ...rows.slice(0, 10))),
        ...(actions ? [SM_ACTIONS] : EMPTY_ARRAY),
      ]),
    ];
    shownCols = cols.filter(colIsVisible);

    width = shownCols.length || 1;

    const searchable = cols.filter(colIsSearchable);
    data = literalData
      ? sortFilter(rows, sort.val, srch.val, searchable)
      : rows;

    // if none of the columns have isId = true, default to id, _id or first col
    rowIdKeys = cols.filter(colIsId);
    if (rowIdKeys.length === 0) {
      if (cols.includes("id")) {
        rowIdKeys = ["id"];
      } else if (cols.includes("_id")) {
        rowIdKeys = ["_id"];
      } else {
        // TODO: warn if no id could be found
        rowIdKeys = [
          cols.filter((col) => !col.startsWith("__sm_"))[0] || "__sm_no_id",
        ];
      }
    }

    rowId = (row) => rowIdKeys.map((key) => row[key] || "").join("::");

    if (literalData) {
      if (srch && !data.length) {
        emptySrchMsg = (
          <td colSpan={width}>
            No matches for "{srch.val}".{" "}
            <span
              role="link"
              onClick={(e) => {
                e.stopPropagation();
                setSearchFieldValue("");
                srch.setVal("");
              }}
            >
              Clear search
            </span>
          </td>
        );
      }
    }

    currPage = page.val || 0;
  }

  useEffect(() => {
    // don't update selection for pagination data
    if (!literalData) return;
    // only update when we have actual data and selection
    if (data === EMPTY_ARRAY) return;
    if (!hasSelection) return;

    const rowIds = data.map(rowId);
    const newSelected = {};
    let dirty = false;
    selectedKeys.forEach((key) => {
      const index = rowIds.indexOf(key);
      if (index < 0) {
        dirty = true;
        return;
      }
      newSelected[key] = data[index];
    });
    if (dirty) {
      _updateSelection(newSelected);
    }
  }, [rawData, literalData]);

  useEffect(() => {
    // only applies to paginated results
    if (pageOffset == null) return;
    if (!hasSelection) return;

    setHasSelectedAll(data.every((row) => selectedRows[rowId(row)]));
  }, [pageOffset, rawData]);

  // // maybe clear out any items that are no longer visible (only done if retainSelection is false)
  // useEffect(() => {
  //   if (retainSelection) return;

  //   const original = rows;
  //   const shown: {}[] = data.filter(Boolean);
  //   if (!original || shown.length === original.length) return;

  //   // the data is smaller than the original set of values, so we know there is
  //   // a filter applied. Go hunt through the selected items and see which ones
  //   // still apply.

  //   const shownIds = shown.map(rowId);
  //   let trimmedSelection = selected;
  //   for (const id in selected) {
  //     const index = shownIds.indexOf(id);
  //     if (index < 0) {
  //       // the id does not exist in the list of shown values

  //       // make a new object if this is the first un-found value
  //       if (trimmedSelection === selected) {
  //         trimmedSelection = { ...selected };
  //       }

  //       delete trimmedSelection[id];
  //     }
  //   }

  //   if (trimmedSelection !== selected) {
  //     updateSelection(trimmedSelection);
  //   }
  // }, [
  //   retainSelection || data,
  //   retainSelection || rows,
  //   retainSelection || selected,
  // ]);

  const hasSearchableCols = cols.length
    ? cols.some(colIsSearchable)
    : columns?.[CATCH_ALL]?.search;

  const searchField = hasSearchableCols ? (
    <input
      type="text"
      placeholder={search}
      value={searchFieldValue}
      onChange={(e) => updateSearch(e.target.value)}
    />
  ) : null;

  let pagination;
  // totalCount
  // < 1 - 50 of 340 >
  // < 1 - 50 of ... >
  // no totalCount
  // < 1 >
  if (cursorData !== null) {
    const { prevCursor, nextCursor, pageLabel } = cursorData;
    const hasPrev = prevCursor !== undefined && prevCursor !== null;
    const hasNext = nextCursor !== undefined && nextCursor !== null;

    pagination = (
      <>
        {hasPrev ? (
          <>
            <Icon
              className="clickable"
              icon="first"
              size={20}
              onClick={() => cursorControlledVal.setVal(null)}
            />
            <Icon
              className="clickable"
              icon="chevron-left"
              size={20}
              onClick={() => cursorControlledVal.setVal(prevCursor)}
            />
          </>
        ) : (
          <>
            <Icon className="disabled" icon="first" size={20} />
            <Icon className="disabled" icon="chevron-left" size={20} />
          </>
        )}
        {pageLabel != null ? <span>{pageLabel}</span> : null}
        {hasNext ? (
          <Icon
            className="clickable"
            icon="chevron-right"
            size={20}
            onClick={() => cursorControlledVal.setVal(nextCursor)}
          />
        ) : (
          <Icon className="disabled" icon="chevron-right" size={20} />
        )}
      </>
    );
  } else if (pageOffset != null) {
    const range = rows.length
      ? `${numfmt(pageOffset + 1)}-${numfmt(pageOffset + rows.length)}`
      : pageOffset
      ? `${numfmt(pageOffset + 1)}+`
      : "0";

    const end =
      hasMore && totalCount?.val
        ? Math.floor((totalCount.val - 1) / pageSize) * pageSize
        : null;

    pagination = (
      <div className="pagination nowrap">
        {pageOffset > 0 ? (
          <Icon
            className="clickable"
            icon="first"
            size={20}
            onClick={() => page.setVal(0)}
          />
        ) : (
          <Icon className="disabled" size={20} icon="first" />
        )}
        {pageOffset > 0 ? (
          <Icon
            className="clickable"
            icon="chevron-left"
            size={20}
            onClick={() => page.setVal(Math.max(pageOffset! - pageSize, 0))}
          />
        ) : (
          <Icon className="disabled" size={20} icon="chevron-left" />
        )}
        <span>
          {totalCount
            ? `${range} of ${totalCount.pending ? "…" : numfmt(totalCount.val)}`
            : range}
        </span>
        {hasMore ? (
          <Icon
            className="clickable"
            icon="chevron-right"
            size={20}
            onClick={() => page.setVal(pageOffset! + pageSize)}
          />
        ) : (
          <Icon className="disabled" size={20} icon="chevron-right" />
        )}
        {end ? (
          <Icon
            size={20}
            className="clickable"
            icon="last"
            onClick={() => page.setVal(end)}
          />
        ) : (
          <Icon className="disabled" size={20} icon="last" />
        )}
      </div>
    );
  }

  const busy = (
    <div
      className="busy"
      aria-busy={rawData.pending && rawData.val ? true : undefined}
    />
  );

  const selection = hasSelection ? (
    <mark className="with_icon">
      {selectedKeys.length} selected&nbsp;
      <Icon className="clickable" icon="x" onClick={clearSelection} />
    </mark>
  ) : null;

  const toolbarBtns = toolbar
    ? toolbar.map((item) => {
        const { label, action, icon } =
          typeof item === "string"
            ? { label: item, action: item, icon: null }
            : item;
        return (
          <button
            className={icon ? "with_icon" : undefined}
            key={action}
            onClick={(e) => onToolbarAction(action)}
          >
            {icon ? <Icon icon={icon} gap={!!label} /> : null}
            {label}
          </button>
        );
      })
    : null;

  const header =
    searchField || label || pagination ? (
      <header>
        <MaybeLabel label={label} rowHasLabel />
        <div className="ctrl_group">
          {busy}
          {selection}
          {toolbarBtns}
          {pagination}
          {searchField}
        </div>
      </header>
    ) : null;

  // TODO: change this to col name
  const [colWidths, setColWidths] = useState<number[] | null>(null);
  const [colAdj, setColAdj] = useState<ColAdj | null>(null);
  // control whether to use exact px or loose % in specifying col widths
  const [exactCols, setExactCols] = useState(false);

  // clear col widths when cols or col.format changes
  useEffect(() => {
    if (!colWidths) return;
    setColWidths(null);
    setExactCols(false);
  }, [shownCols.join(","), cols.map((c) => c.format || "auto").join(",")]);

  function computeWidths(tr: HTMLTableRowElement) {
    if (!tr || colWidths) return;
    // TODO: enforce MIN_COL_WIDTH?
    setColWidths([...tr.children].map((col) => col.clientWidth));
  }

  function adjustedColWidths(colAdj: ColAdj, colWidths: number[]) {
    const { col, dx } = colAdj;
    // constrain dx
    const mdx =
      dx < 0
        ? Math.max(dx, MIN_COL_WIDTH - colWidths[col])
        : Math.min(dx, colWidths[col + 1] - MIN_COL_WIDTH);

    // if the table is wider than the viewport (we use the figure width to determine that),
    // avoid shrinking the next column - the table already has a horizontal scroll.
    const shrinkNextColumn =
      !colAdj.shift &&
      figureRef.current &&
      figureRef.current.clientWidth > (tableMinWidth ?? 0);

    return colWidths.map((cw, i) => {
      if (i === col) {
        cw = cw + mdx;
      } else if (i === col + 1 && shrinkNextColumn) {
        cw = cw - mdx;
      }
      return cw;
    });
  }

  function renderHeader() {
    if (emptyMsg) return <tr>{emptyMsg}</tr>;

    if (!shownCols.length)
      return (
        <tr>
          <th>&nbsp;</th>
        </tr>
      );

    type ColWidth = { width: string } | undefined;

    // TODO: resize strategy that minimized overflowing columns
    // TODO: on mobile, need to set px width vs auto;
    let widths: ColWidth[];

    if (colWidths) {
      if (colAdj) {
        widths = adjustedColWidths(colAdj, colWidths).map((cw) => ({
          width: px(cw),
        }));
      } else if (exactCols) {
        // if widths were adjusted, use exact pixels
        widths = colWidths.map((cw) => ({ width: px(cw) }));
      } else {
        const totalWidthMinusFixedColumns = sum(
          colWidths.filter((_, index) => !colIsNarrow(shownCols[index]))
        );

        widths = colWidths.map((cw, index) => {
          const width = colIsNarrow(shownCols[index])
            ? `${cw}px`
            : pcnt(
                ((cw / totalWidthMinusFixedColumns > 0.2 ? 0.9 : 1) * cw) /
                  totalWidthMinusFixedColumns
              );

          return { width };
        });
      }
    } else {
      const expands = shownCols.filter((col) => columns?.[col]?.expand).length;
      widths = shownCols.map((col) =>
        columns?.[col]?.expand
          ? { width: pcnt(1 / (expands + 0.5)) }
          : undefined
      );
    }

    const s = () => widths.shift();

    const empty = shownCols.some((col) => colLabel(col)) ? undefined : "empty";
    const fallback = empty ? null : (
      <span className="zeroWidthSpace">&#8203;</span>
    );

    const last = shownCols.length - 1;
    return (
      <tr>
        {shownCols.map((col: string, i: number) => {
          if (col === SM_MULTISELECT) {
            return (
              <th className="narrow" style={s()} key={col}>
                <input
                  type="checkbox"
                  checked={hasSelectedAll}
                  onChange={(e) => {
                    // add current rows to selection
                    if (e.target.checked) {
                      setHasSelectedAll(true);
                      _updateSelection({
                        ...selectedRows,
                        ...Object.fromEntries(
                          data.map((row) => [rowId(row), row])
                        ),
                      });
                      onSelected(true);
                    } else {
                      setHasSelectedAll(false);
                      const toDelete = data
                        .map((row) => rowId(row))
                        .filter((key) => selectedRows[key]);
                      if (toDelete.length) {
                        toDelete.forEach((key) => delete selectedRows[key]);
                        _updateSelection({ ...selectedRows });
                        onUnselected(true);
                      }
                    }
                  }}
                />
              </th>
            );
          }

          return (
            <th
              style={s()}
              className={cx(empty, colIsNarrow(col) ? "narrow" : undefined)}
              key={col}
              onClick={
                colIsSortable(col) ? (e) => void updateSort(col) : undefined
              }
            >
              <div className="col_adj_container">
                {colLabel(col) || fallback}
                {i !== last ? (
                  colAdj ? (
                    i === colAdj.col ? (
                      <div className="col_adj col_adj_active" />
                    ) : (
                      <div className="col_adj" />
                    )
                  ) : (
                    <div
                      className="col_adj col_adj_hover"
                      onMouseDown={(e) => {
                        // record current actual col widths
                        setColWidths(
                          [...tableRef.current!.rows[0]!.children].map(
                            (col) => col.clientWidth
                          )
                        );
                        e.stopPropagation();
                        setColAdj({
                          col: i,
                          shift: e.shiftKey,
                          x: e.clientX,
                          dx: 0,
                        });
                      }}
                    />
                  )
                ) : null}
              </div>
            </th>
          );
        })}
      </tr>
    );
  }

  // pad data to make sure we have some filler rows
  const padded = size !== "rows" && needsPad ? [...data, ...EMPTY_DATA] : data;

  const itemContent = (index: number) => {
    const row = padded[index];
    if (!row) {
      if (index === 0 && emptySrchMsg) return emptySrchMsg;
      return <td colSpan={width}>&#8203;</td>;
    }
    const key = rowId(row);
    const isSelected = !!selectedRows[key];
    return shownCols.map((col) => renderCell(row, col, isSelected, actions));
  };

  // Unfortunately, min-width does not work on a column level, that would spare us from having to do this.
  // Determining the column min-width can be more sophisticated - we can use the column type or let the developer specify it.
  const tableMinWidth = colWidths
    ? sum(
        colWidths.map((value, idx) =>
          colIsNarrow(shownCols[idx])
            ? value
            : clip(value, MIN_COL_WIDTH, DEFAULT_MIN_COLUMN_WIDTH)
        )
      )
    : undefined;

  const tableContext: TableVirtuosoContext = {
    colWidths,
    tableRef,
    toggleSelection,
    data,
    selectedRows,
    rowActions: actions,
    onRowAction,
    shownCols,
    columns,
    tableMinWidth,
    padded,
    onRowClick,
    rowId,
    computeWidths,
    clearSelection,
    multiSelect,
    select,
  };

  function computeNeedsPad() {
    if (!figureRef.current) return;
    if (!tableRef.current) return;
    const f = figureRef.current;
    const t = tableRef.current;

    const headerHeight = t.tHead ? t.tHead.clientHeight : 0;
    const bodyHeight = f.clientHeight - headerHeight;
    let dataHeight = 0;
    for (const tr of t.querySelectorAll("tr[data-key]")) {
      if ((dataHeight += tr.clientHeight) > bodyHeight) {
        setNeedsPad(false);
        return;
      }
    }
    setNeedsPad(true);
  }

  useEffect(() => {
    computeNeedsPad();
  }, [size]);

  useEffect(() => {
    const handle = debounce(computeNeedsPad, 250);
    window.addEventListener("resize", handle);
    return () => window.removeEventListener("resize", handle);
  }, []);

  const table =
    size === "rows" ? (
      <table
        ref={tableRef}
        className={colWidths ? "ui_table fixed" : "ui_table"}
        {...(tableMinWidth ? { style: { minWidth: tableMinWidth } } : {})}
      >
        <thead>{renderHeader()}</thead>
        <tbody {...useTableContextMenu(tableContext)}>
          {padded.map((row, index) => (
            <TableRow
              data-known-size={0}
              data-item-index={index}
              data-index={index}
              style={{}}
              key={index}
              context={tableContext}
            >
              {itemContent(index)}
            </TableRow>
          ))}
        </tbody>
      </table>
    ) : (
      <TableVirtuoso
        style={{ overflowY: needsPad ? "hidden" : "auto" }}
        ref={virtuosoRef}
        data={padded}
        initialItemCount={Math.min(data.length, 200)}
        overscan={Math.min(data.length, 10)}
        totalListHeightChanged={() => setTimeout(computeNeedsPad, 0)}
        context={tableContext}
        components={{
          ...VirtuosoCustomComponents,
        }}
        fixedHeaderContent={renderHeader}
        itemContent={itemContent}
      />
    );

  const footer = null;

  const rtl = document.documentElement.dir === "rtl" ? -1 : 1;

  return (
    <div className={cx("ui_table_wrapper", size)}>
      {header}
      <figure
        className={colAdj ? "is_adjusting_col_widths" : undefined}
        ref={figureRef}
      >
        {table}
      </figure>
      {footer}
      {colAdj ? (
        <FloatingPortal>
          <div
            className="col_adj_overlay"
            onMouseMove={(e) =>
              setColAdj((o) =>
                o ? { ...o, dx: rtl * (e.clientX - o.x) } : null
              )
            }
            onMouseUp={(e) => {
              setColWidths(adjustedColWidths(colAdj, colWidths!));
              setColAdj(null);
              setExactCols(true);
            }}
          />
        </FloatingPortal>
      ) : null}
    </div>
  );
}

function handleArrays<T>(fn: (a: T, b: T) => number) {
  return (a: T | T[], b: T | T[]) => {
    if (Array.isArray(a)) {
      if (Array.isArray(b)) {
        const l = Math.min(a.length, b.length);
        let order = 0;
        for (let i = 0; !order && i < l; ++i) {
          order = fn(a[i], b[i]);
        }
        return order || a.length - b.length;
      } else {
        return fn(a[0], b) || 1;
      }
    } else if (Array.isArray(b)) {
      return fn(a, b[0]) || -1;
    }

    return fn(a, b);
  };
}

function cmp(a: Renderable | null, b: Renderable | null) {
  if (a == null) {
    if (b == null) return 0;
    return -1;
  } else if (b == null) {
    return 1;
  }
  if (typeof a !== typeof b) {
    return a.toString().localeCompare(b.toString());
  }
  if (typeof a === "string" && typeof b === "string") {
    return a.localeCompare(b);
  }
  return a > b ? 1 : a < b ? -1 : 0;
}

type Link = Renderable | { title: Renderable; href: string };

function fieldOrVal(o: any, field: string): any {
  return o != null && typeof o === "object"
    ? field in o
      ? o[field]
      : null
    : o;
}

function sortLink(a: Link, b: Link) {
  return cmp(fieldOrVal(a, "title"), fieldOrVal(b, "title"));
}

type Profile = { name: Renderable; subtitle?: Renderable; avatar?: string };

function sortProfile(a: Profile, b: Profile) {
  return cmp(a.name, b.name);
}

type Labelled = Renderable | { label: string };

function sortLabeled(a: Labelled, b: Labelled) {
  return cmp(fieldOrVal(a, "label"), fieldOrVal(b, "label"));
}

function sortStackedBar(a: StackBarSegment[], b: StackBarSegment[]) {
  const total_a = sum(a.map((s) => fieldOrVal(s, "value") || 0));
  const total_b = sum(b.map((s) => fieldOrVal(s, "value") || 0));
  return total_a > total_b ? 1 : total_a < total_b ? -1 : 0;
}

function normalizeAction(theAction: Action) {
  let action: string, label: Renderable | null, icon: string | undefined;
  if (typeof theAction === "string") {
    action = label = theAction;
  } else {
    action = theAction.action;
    icon = theAction.icon;
    label = theAction.label != null ? theAction.label : icon ? null : action;
  }
  const lbl = renderValue(label);
  return { action, label: lbl, icon };
}

const _sortProfile = handleArrays(sortProfile);

const _sortLink = handleArrays(sortLink);

const _cmp = handleArrays(cmp);

const _sortLabeled = handleArrays(sortLabeled);

export function sortFnForFormat(
  format?: ColFormats
): (a: any, b: any) => number {
  switch (format) {
    case "action":
    case "hover_action":
      return _sortLabeled;
    case "link":
    case "_link":
    case "link_new_tab":
    case "link_same_tab":
      return _sortLink;
    case "profile":
      return _sortProfile;
    case "pill":
      return _sortLabeled;
    case "icon_label":
      return _sortLabeled;
    case "stacked_bar": // array
      return sortStackedBar;
    default:
      return _cmp;
  }
}

function useTableContextMenu(context: TableVirtuosoContext) {
  function isActionColumn(colName: string) {
    const col = context.columns[colName];
    return col && ACTION_FORMATS.includes(col.format!);
  }

  return useSkyMassContextMenu((e) => {
    const originEl = e.target as HTMLElement;
    const rowEl = originEl.closest("tr");
    const cellEl = originEl.closest("td");

    if (!rowEl || !cellEl) {
      throw new Error("Context menu triggered from invalid target");
    }

    if (rowEl.dataset.key === undefined) {
      return;
    }

    const key = String(rowEl.dataset.key);
    const isSelected = context?.selectedRows[key] ?? false;
    const rowIndex = Number(rowEl.dataset.index);
    const rowObj = context?.data[rowIndex];
    const cellContent = cellEl.textContent ?? "";

    // clone the actions, because we push below
    const actions = (
      context?.rowActions
        ? context.rowActions instanceof Array
          ? context.rowActions
          : [context.rowActions]
        : []
    ).slice();

    context?.shownCols.forEach((colName) => {
      if (isActionColumn(colName)) {
        const colActions = rowObj[colName];
        if (Array.isArray(colActions)) {
          actions.push(...colActions);
        } else {
          actions.push(colActions);
        }
      }
    });

    const colIndex = Array.from(rowEl.children).indexOf(cellEl);
    const colName = context?.shownCols[colIndex];

    const showCopy =
      !isActionColumn(colName) && !SPECIAL_COLS.includes(colName);

    const actionItems: MenuItems = [];

    if (showCopy) {
      actionItems.push({
        icon: "copy",
        label: "Copy",
        onClick: () => {
          navigator.clipboard.writeText(cellContent);
        },
      });
    }

    if (context.select) {
      actionItems.push({
        label: isSelected ? "Unselect" : "Select",
        icon: isSelected ? "square" : "check-square",
        onClick: () => {
          context?.toggleSelection(key, rowIndex, !isSelected);
        },
      });
    }

    if (actions.length > 0) {
      if (actionItems.length > 0) {
        actionItems.push(MENU_SEPARATOR);
      }
      actionItems.push(
        ...tableActionsToMenuActions(actions, context.onRowAction, rowObj)
      );
    }
    return actionItems;
  });
}

function tableActionsToMenuActions(
  actions: Actions,
  onRowAction: TableVirtuosoContext["onRowAction"],
  row: {}
): MenuItem[] {
  return actions.map((theAction) => {
    const { action, label, icon } = normalizeAction(theAction);
    return {
      type: "item" as const,
      label: label ? label : capitalizeFirstLetter(icon),
      icon,
      onClick: () => onRowAction({ action, row }, action),
    };
  });
}

function capitalizeFirstLetter(string = "") {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

type RowMouseEvent = React.MouseEvent<HTMLTableRowElement, MouseEvent>;

function useTap(callback: (e: RowMouseEvent) => void) {
  const mouseDownCoordinates = useRef({ x: 0, y: 0 });

  return {
    onMouseDown: (e: RowMouseEvent) => {
      mouseDownCoordinates.current = { x: e.clientX, y: e.clientY };
    },
    onClick: (e: RowMouseEvent) => {
      // calculate the distance between the mouse down and mouse up coordinates
      const dx = Math.abs(e.clientX - mouseDownCoordinates.current.x);
      const dy = Math.abs(e.clientY - mouseDownCoordinates.current.y);
      // Pitagoras theorem
      const distance = Math.sqrt(dx * dx + dy * dy);
      if (distance < 5) {
        callback(e);
      }
    },
  };
}
