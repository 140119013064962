import React, { ReactNode } from "react";
import { RowProps } from "../Row";
import { useControlledVal, useHandleMethodCall } from "../server_hooks";
import { Updatable } from "../useUpdatableProps";

import { MaybeLabel } from "./MaybeLabel";

type Props = {
  label?: string;
  disabled?: boolean;
  autoFocus?: boolean;
} & RowProps;

export function ColorWidget(props: Props) {
  const ref = React.useRef<HTMLInputElement>(null);
  const { label, disabled, rowHasLabel } = props;
  const val = useControlledVal<string>();

  useHandleMethodCall("focus", () => {
    ref.current?.focus();
    ref.current?.scrollIntoView();
  });

  const input = (
    <input
      ref={ref}
      style={{ width: "5em", display: "block" }}
      type="color"
      value={val.val}
      disabled={disabled}
      autoFocus={props.autoFocus}
      onChange={(e) => void val.setVal(e.target.value || "#000000", true, 100)}
    />
  );

  return (
    <label>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      {input}
    </label>
  );
}
