export var AdvancedSerializeTypes;
(function (AdvancedSerializeTypes) {
    AdvancedSerializeTypes[AdvancedSerializeTypes["UNRECOGNIZED"] = 0] = "UNRECOGNIZED";
    AdvancedSerializeTypes[AdvancedSerializeTypes["Subject"] = 1] = "Subject";
    AdvancedSerializeTypes[AdvancedSerializeTypes["Date"] = 2] = "Date";
    AdvancedSerializeTypes[AdvancedSerializeTypes["InvalidValue"] = 3] = "InvalidValue";
    AdvancedSerializeTypes[AdvancedSerializeTypes["ValueUnchanged"] = 4] = "ValueUnchanged";
    AdvancedSerializeTypes[AdvancedSerializeTypes["KeyDeleted"] = 5] = "KeyDeleted";
})(AdvancedSerializeTypes || (AdvancedSerializeTypes = {}));
export class Slot {
    constructor(id) {
        this.id = id;
    }
}
export var RegionType;
(function (RegionType) {
    /** These regions should render as a modal */
    RegionType[RegionType["Modal"] = 0] = "Modal";
    /** These regions needs to be redeclared every render or it unmounts */
    RegionType[RegionType["Component"] = 1] = "Component";
    /** These regions stay until they close or are replaced */
    RegionType[RegionType["Launched"] = 2] = "Launched";
    /** These regions totally replace their parent, until they are closed */
    RegionType[RegionType["Cover"] = 3] = "Cover";
    /** These regions are super-modals (things like a confirm dialog) */
    RegionType[RegionType["Alert"] = 4] = "Alert";
})(RegionType || (RegionType = {}));
export const RootRegionId = "__root";
export var BuiltinActions;
(function (BuiltinActions) {
    BuiltinActions["refresh"] = "__refresh";
})(BuiltinActions || (BuiltinActions = {}));
export var LogLevel;
(function (LogLevel) {
    LogLevel["Log"] = "log";
    LogLevel["Info"] = "info";
    LogLevel["Warn"] = "warn";
    LogLevel["Error"] = "error";
})(LogLevel || (LogLevel = {}));
export var UpdateType;
(function (UpdateType) {
    UpdateType[UpdateType["Mutation"] = 0] = "Mutation";
    UpdateType[UpdateType["Event"] = 1] = "Event";
})(UpdateType || (UpdateType = {}));
