import { RowProps } from "../Row";
import {
  useControlledVal,
  useEvent,
  useHandleMethodCall,
} from "../server_hooks";
import { ImgSize } from "./ImgSize";
import React, { useState } from "react";
import { MaybeLabel } from "./MaybeLabel";
import ReactPlayer from "react-player/lazy";
import { cx } from "./renderValue";

export const MATCH_URL_YOUTUBE =
  /(?:youtu\.be\/|youtube(?:-nocookie|education)?\.com\/(?:embed\/|v\/|watch\/|watch\?v=|watch\?.+&v=|shorts\/|live\/))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//;

export type Props = {
  id: string;
  src: string;
  loop?: boolean;
  autoPlay?: boolean;
  label?: string;
  caption?: string;
  size?: ImgSize;
  width?: number;
  height?: number;
  aspect?: string;
} & RowProps;

export function VideoWidget(props: Props) {
  const val = useControlledVal<number>("val");
  const played = useControlledVal<number>("played");
  const playing = useControlledVal<boolean>("playing");

  const onPause = useEvent("pause");
  const onPlay = useEvent("play");

  const [lastFlush, setLastFlush] = useState(-10);

  const {
    id,
    src,
    loop = false,
    autoPlay = false,
    label,
    caption,
    width,
    height,
    aspect,
    rowHasLabel,
    size,
  } = props;

  let video;

  const aspectRatio = aspect
    ? aspect
    : width && height
    ? `${width}/${height}`
    : undefined;

  if (MATCH_URL_YOUTUBE.test(src)) {
    const ref = React.useRef<ReactPlayer>(null);

    useHandleMethodCall("seekTo", (time: number) => {
      ref.current?.seekTo(time);
    });

    useHandleMethodCall("play", () => playing.setVal(true));
    useHandleMethodCall("pause", () => playing.setVal(false));

    video = (
      <ReactPlayer
        ref={ref}
        url={src}
        controls
        loop={loop ? true : undefined}
        playing={playing.val}
        muted={autoPlay ? true : undefined}
        width={aspectRatio ? "100%" : width}
        height={aspectRatio ? "100%" : height}
        onProgress={(e) => {
          const t = e.played;
          const debounce = t - lastFlush > 0.5 ? 0 : 500;
          if (!debounce) setLastFlush(t);
          val.setVal(t, true, debounce);
          played.setVal(t, true, debounce);
        }}
        onPlay={() => {
          playing.setVal(true);
          onPlay(true);
        }}
        onPause={() => {
          playing.setVal(false);
          onPause(true);
        }}
      />
    );
  } else {
    const ref = React.useRef<HTMLVideoElement>(null);

    useHandleMethodCall("seekTo", (time: number) => {
      if (ref.current) {
        ref.current.currentTime = time;
      }
    });

    useHandleMethodCall("play", () => ref.current?.play());
    useHandleMethodCall("pause", () => ref.current?.pause());

    video = (
      <video
        ref={ref}
        src={src}
        controls
        loop={loop ? true : undefined}
        autoPlay={autoPlay ? true : undefined}
        muted={autoPlay ? true : undefined}
        width={width}
        height={height}
        onTimeUpdate={(e) => {
          const v = e.target as HTMLVideoElement;
          const t = v.currentTime;
          const debounce = t - lastFlush > 0.5 ? 0 : 500;
          if (!debounce) setLastFlush(t);
          val.setVal(t, true, debounce);
          played.setVal(t, true, debounce);
        }}
        onPlay={() => {
          playing.setVal(true);
          onPlay(true);
        }}
        onPause={() => {
          playing.setVal(false);
          onPause(true);
        }}
      />
    );
  }

  return (
    <figure>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      {aspectRatio ? (
        <div style={{ aspectRatio }} className={cx("media_container", size)}>
          {video}
        </div>
      ) : (
        video
      )}
      {caption ? <caption>{caption}</caption> : null}
    </figure>
  );
}
