import React, { useState } from "react";
import { RowProps } from "../Row";
import { useHandleMethodCall, useValidatedVal } from "../server_hooks";
import { Updatable } from "../useUpdatableProps";
import { ToolTip } from "./ToolTip";
import { valOrLiteral, MaybeLabel } from "./MaybeLabel";
import { ariaInvalid } from "./CheckboxGroupWidget";

type Props = {
  label?: string;
  placeholder?: Updatable<string> | string;
  min?: string;
  max?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
} & RowProps;

function ph(min?: string, max?: string) {
  return min != null
    ? max != null
      ? `A time between ${min} and ${max}`
      : `A time later than ${min}`
    : max != null
    ? `A time earlier than ${max}`
    : undefined;
}

export function TimeWidget(props: Props) {
  const {
    label,
    min,
    max,
    placeholder,
    required,
    disabled,
    autoFocus,
    readOnly,
    rowHasLabel,
  } = props;
  const [focus, setFocus] = useState(false);
  const { setVal, val, error, checkErrors, ref } = useValidatedVal<string>();

  useHandleMethodCall("focus", () => {
    ref.current?.focus();
    ref.current?.scrollIntoView();
  });

  //useEffect(() => {
  //  checkErrors();
  //}, [min, max, required]);

  const hasConstraints = !!required || min !== undefined || max !== undefined;

  return (
    <label>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <ToolTip message={focus && !!error ? error : undefined}>
        <input
          aria-invalid={ariaInvalid(error, hasConstraints)}
          ref={ref}
          type="time"
          value={val}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
          autoFocus={autoFocus}
          placeholder={valOrLiteral(placeholder) ?? ph(min, max)}
          min={min}
          max={max}
          onFocus={(e) => setFocus(true)}
          onBlur={(e) => {
            checkErrors();
            setFocus(false);
          }}
          onChange={(e) => {
            const time = e.target.value;
            setVal(time ? time : "");
          }}
        />
      </ToolTip>
    </label>
  );
}
