import produce, { Draft } from "immer";
import { useCallback, useReducer, useState } from "react";

export function useImmer<State, Action>(
  reducer: (state: Draft<State>, action: Action) => void | State,
  initialState: State
): [State, (action: Action) => void] {
  const [state, setState] = useState(initialState);

  function dispatch(action: Action) {
    // @ts-expect-error weird void/undefined complaint
    setState((prev) => produce(prev, (state) => reducer(state, action)));
  }

  return [state, useCallback(dispatch, [reducer])];
}
