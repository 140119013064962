import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import { Applet } from "./Applet";
import { Header, AuthState } from "./Header";
import { getSubdomainAndPath } from "./paths";

function App() {
  useEffect(() => {
    function reload() {
      document.location.reload();
    }
    AuthState.addEventListener("auth_change", reload);
    return () => AuthState.removeEventListener("auth_change", reload);
  }, []);

  const { subdomain, path } = getSubdomainAndPath(location.href);

  return (
    <>
      <Header />
      <main className="container applet">
        {path === "/" ? (
          <Applet
            host={window.location.host}
            subdomain="skymass"
            path="/directory"
            params={{ url: document.location.href }}
          />
        ) : (
          <Applet
            host={window.location.host}
            subdomain={subdomain}
            path={path}
            params={{ url: document.location.href }}
          />
        )}
      </main>
    </>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
