import React, { useState } from "react";
import { RowProps } from "../Row";
import { useControlledVal, useHandleMethodCall } from "../server_hooks";
import { Updatable } from "../useUpdatableProps";

import { valOrLiteral, MaybeLabel } from "./MaybeLabel";

type Props = {
  label?: string;
  placeholder?: Updatable<string> | string; // TODO: remove or display
  min?: number;
  max?: number;
  step?: number;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  debounce?: number;
} & RowProps;

export function RangeWidget(props: Props) {
  const {
    label,
    min,
    max,
    step,
    required,
    readOnly,
    disabled,
    autoFocus,
    rowHasLabel,
    debounce = 100,
  } = props;

  const ref = React.useRef<HTMLInputElement>(null);
  // TODO: would be nice to show a tooltip with the current value when it is being modified
  const [focus, setFocus] = useState(false);
  const { setVal, val } = useControlledVal<number>();

  useHandleMethodCall("focus", () => {
    ref.current?.focus();
    ref.current?.scrollIntoView();
  });

  return (
    <label>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <input
        ref={ref}
        type="range"
        value={val}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
        autoFocus={autoFocus}
        min={min}
        max={max}
        step={step}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onChange={(e) => {
          const str = e.target.value;
          setVal(+str, true, debounce);
        }}
      />
    </label>
  );
}
