import React, {
  FocusEvent,
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { RowProps } from "../Row";
import { useHandleMethodCall, useValidatedVal } from "../server_hooks";
import { bgcolor_mute_class, debounce } from "./renderValue";
import {
  size,
  useFloating,
  autoUpdate,
  useRole,
  flip,
  FloatingPortal,
  useListNavigation,
  useInteractions,
  useDismiss,
  FloatingFocusManager,
  useId,
} from "@floating-ui/react";

import Radar from "radar-sdk-js";
import { MaybeLabel } from "./MaybeLabel";
import { ToolTip } from "./ToolTip";
import { ariaInvalid } from "./CheckboxGroupWidget";

Radar.initialize("prj_test_pk_cf5c4a4d043476cab08ad5003c4140cab36cd3f7");

type Props = {
  id: string;
  label: string;
  required?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
} & RowProps;

function stop(e: any) {
  e.preventDefault();
  e.stopPropagation();
}

type Address = {
  formattedAddress: string;
  name?: string;
  latitude: number;
  longitude: number;
  neighborhood?: string;
  city?: string;
  state?: string;
  stateCode?: string;
  country?: string;
  countryCode?: string;
  postalCode?: string;
};

// TODO: switch this out to a regular fetch request
function radarRequest(
  query: string
): Promise<{ addresses: Address[]; query: string }> {
  return new Promise((resolve, reject) => {
    Radar.autocomplete({ query }, function (err, result) {
      if (err) reject(err);
      const addresses = result.addresses.map(
        ({
          formattedAddress,
          placeLabel: name,
          latitude,
          longitude,
          neighborhood,
          borough,
          city,
          state,
          stateCode,
          country,
          countryCode,
          postalCode,
        }) => ({
          formattedAddress,
          name,
          latitude,
          longitude,
          neighborhood,
          borough,
          city,
          state,
          stateCode,
          country,
          countryCode,
          postalCode,
        })
      );
      resolve({ addresses, query });
    });
  });
}

function normalize(input = "") {
  return input.trim().replace(/\s+/, " ");
}

const doSearch = (query, cb) => {
  return radarRequest(query)
    .then(cb)
    .catch((e) => console.log(e));
};

const debouncedSearch = debounce(doSearch);

// TODO: no need to use multipleselection
export function LocationSearchWidget(props: Props) {
  const { id, label, placeholder, rowHasLabel, autoFocus, disabled, required } =
    props;

  const [options, setOptions] = useState<Address[]>([]);
  const [searching, setSearching] = useState<boolean>(false);
  const currentQuery = useRef("");

  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const {
    val: selectedItem,
    setVal: setSelectedItem,
    ref,
    error,
    checkErrors,
    focusRef,
  } = useValidatedVal<Address | undefined>();

  useHandleMethodCall("focus", () => {
    focusRef.current?.focus();
  });

  // currently highlighted item in the dropdown
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  // currently highlighted item in the values list
  const [activeItem, setActiveItem] = useState<boolean>(false);

  const listRef = useRef<Array<HTMLElement | null>>([]);

  function updateSelected(value: Address | undefined) {
    if (ref.current) {
      ref.current.value = value ? "value" : "";
    }
    setSelectedItem(value);
    setActiveItem(activeItem && !!value);
  }

  const q = normalize(inputValue);

  useEffect(() => {
    if (!q) {
      setSearching(false);
      setOptions([]);
    } else if (q.length > 1) {
      currentQuery.current = q;
      setSearching(true);
      debouncedSearch(inputValue, ({ addresses, query }) => {
        // check for stale
        if (query !== currentQuery.current) return;
        setSearching(false);
        setOptions(addresses);
      });
    }
  }, [q]);

  const { x, y, strategy, refs, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
    middleware: [
      flip(),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 0,
      }),
    ],
  });

  const role = useRole(context, { role: "listbox" });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [role, dismiss, listNav]
  );

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setInputValue(value);

    if (value) {
      setOpen(true);
      setActiveIndex(0);
    } else {
      setOpen(false);
    }
  }

  function reset() {
    setInputValue("");
    setActiveIndex(null);
    setOpen(false);
    setActiveItem(false);
  }

  useEffect(() => {
    if (error !== undefined) checkErrors();
  }, [!!selectedItem, error]);

  return (
    <div className="combobox" key={id} onClick={stop} aria-disabled={disabled}>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />

      <ToolTip message={focus && !!error ? error : undefined}>
        <details role="list" open={open}>
          <summary
            ref={refs.setPositionReference}
            aria-invalid={ariaInvalid(error, !!required)}
            tabIndex={-1}
            role={undefined}
            onFocus={(e) => {
              refs.domReference.current?.focus();
              setFocus(true);
            }}
            onBlur={(e) => {
              checkErrors();
              setFocus(false);
            }}
            onMouseDown={(e) => {
              setOpen((open) => !open);
            }}
          >
            <div
              className="pills"
              style={{ overflow: "hidden", flexWrap: "nowrap" }}
            >
              {selectedItem ? (
                <code
                  className={bgcolor_mute_class("something")}
                  onMouseDown={(e) => e.stopPropagation()}
                  data-ignore
                >
                  <span title={itemToString(selectedItem)} className="nowrap">
                    {itemToString(selectedItem)}
                  </span>{" "}
                  <span
                    onClick={(e) => {
                      stop(e);
                      updateSelected(undefined);
                    }}
                  >
                    &#10005;
                  </span>
                </code>
              ) : null}
              <input
                {...getReferenceProps({
                  ref: (el: HTMLInputElement) => {
                    refs.setReference(el);
                    focusRef.current = el;
                  },
                  onChange,
                  value: inputValue,
                  type: "text",
                  style: { minWidth: "5em" },
                  placeholder,
                  disabled,
                  autoFocus,
                  "aria-autocomplete": "list",
                  onFocus(event) {
                    stop(event);
                    setOpen(true);
                  },
                  onKeyDown(event) {
                    switch (event.key) {
                      case "Enter": {
                        if (activeIndex != null && options[activeIndex]) {
                          stop(event); // so we don't submit the form
                          updateSelected(options[activeIndex]);
                          reset();
                        }
                        break;
                      }
                      case "Delete":
                      case "Backspace": {
                        setOpen(false);
                        if (inputValue.trim()) return;
                        if (!selectedItem) return;
                        updateSelected(undefined);
                        break;
                      }
                      case "ArrowLeft": {
                        if (!selectedItem) return;
                        if (inputValue.trim()) return;
                        setActiveItem(true);
                        break;
                      }
                      case "ArrowRight": {
                        if (!activeItem) return;
                        setActiveItem(false);
                        refs.domReference.current?.focus();
                        break;
                      }
                    }
                  },
                })}
              />
            </div>
          </summary>
        </details>

        <input
          ref={ref}
          type="text"
          className="display_none"
          required={required}
          value={selectedItem ? "value" : ""}
          onChange={stop}
        />
      </ToolTip>
      {open && (
        <FloatingPortal>
          <FloatingFocusManager
            context={context}
            initialFocus={-1}
            visuallyHiddenDismiss
          >
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  position: strategy,
                  left: x ?? 0,
                  top: y ?? 0,
                  overflowY: "auto",
                  boxShadow: "var(--card-box-shadow)",
                },
              })}
            >
              <ul role="menu">
                {options.length ? (
                  options.map((item, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Item
                      {...getItemProps({
                        key: `${index}`,
                        ref(node) {
                          listRef.current[index] = node;
                        },
                        onClick() {
                          updateSelected(item);
                          setInputValue("");
                          refs.domReference.current?.focus();
                          setOpen(false);
                          setActiveItem(false);
                        },
                      })}
                      active={activeIndex === index}
                    >
                      <a
                        title={item.formattedAddress}
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        {item.name ? (
                          <>
                            {pill(item.name)} {item.formattedAddress}
                          </>
                        ) : (
                          item.formattedAddress
                        )}
                      </a>
                    </Item>
                  ))
                ) : searching ? (
                  <li aria-busy={true}>Searching for "{q}"...</li>
                ) : q.length > 1 ? (
                  <li>No matches for "{q}"</li>
                ) : (
                  <li>Type an address or query...</li>
                )}
              </ul>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  );
}

function pill(text: string) {
  return <code className={bgcolor_mute_class(text)}>{text}</code>;
}

function itemToString(x: Address | undefined | null) {
  if (!x) return "";
  return x.name ? `${x.name}: ${x.formattedAddress}` : x.formattedAddress;
}

type ItemProps = {
  children: ReactNode;
  active: boolean;
};

const Item = forwardRef<
  HTMLLIElement,
  ItemProps & React.HTMLProps<HTMLLIElement>
>(({ children, active, ...rest }, ref) => (
  <li
    ref={ref}
    role="option"
    id={useId()}
    aria-selected={active}
    {...rest}
    className={active ? "selected" : undefined}
  >
    {children}
  </li>
));
