import React, { useState } from "react";
import { RowProps } from "../Row";
import { useHandleMethodCall, useValidatedVal } from "../server_hooks";
import { Updatable } from "../useUpdatableProps";

import { ToolTip } from "./ToolTip";
import { valOrLiteral, MaybeLabel } from "./MaybeLabel";
import { ariaInvalid } from "./CheckboxGroupWidget";
import { pad } from "./renderValue";

type Props = {
  label?: string;
  placeholder?: Updatable<string> | string;
  min?: Date;
  max?: Date;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
} & RowProps;

// TODO: toLocaleString does not match the Date's TZ
function ph(min?: Date, max?: Date) {
  return min != null
    ? max != null
      ? `A value between ${min.toLocaleString()} and ${max.toLocaleString()}`
      : `A value later than ${min.toLocaleString()}`
    : max != null
    ? `A value earlier than ${max.toLocaleString()}`
    : undefined;
}

function str(date: Date): string {
  const yyyy = date.getFullYear();
  const MM = pad(date.getMonth() + 1); // getMonth() returns 0-11
  const dd = pad(date.getDate());
  const HH = pad(date.getHours());
  const mm = pad(date.getMinutes());

  // Combine components in the YYYY-MM-DDTHH:mm format
  return `${yyyy}-${MM}-${dd}T${HH}:${mm}`;
}

export function DateTimeWidget(props: Props) {
  const {
    label,
    min,
    max,
    placeholder,
    required,
    disabled,
    autoFocus,
    readOnly,
    rowHasLabel,
  } = props;
  const [focus, setFocus] = useState(false);
  const { setVal, val, error, checkErrors, ref } = useValidatedVal<"" | Date>();

  useHandleMethodCall("focus", () => {
    ref.current?.focus();
    ref.current?.scrollIntoView();
  });

  const minP = min ? str(min) : undefined;
  const maxP = max ? str(max) : undefined;

  const hasConstraints = !!required || min !== undefined || max !== undefined;

  return (
    <label>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <ToolTip message={focus && !!error ? error : undefined}>
        <input
          aria-invalid={ariaInvalid(error, hasConstraints)}
          ref={ref}
          type="datetime-local"
          value={val ? str(val) : ""}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
          autoFocus={autoFocus}
          placeholder={valOrLiteral(placeholder) ?? ph(min, max)}
          min={minP}
          max={maxP}
          onFocus={(e) => setFocus(true)}
          onBlur={(e) => {
            checkErrors();
            setFocus(false);
          }}
          onChange={(e) => {
            const ms = e.target.valueAsNumber;
            if (ms) {
              const date = new Date(ms);
              date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
              setVal(date);
            } else {
              setVal("");
            }
          }}
        />
      </ToolTip>
    </label>
  );
}
