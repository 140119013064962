import React, { useState } from "react";
import { RowProps } from "../Row";
import { useHandleMethodCall, useValidatedVal } from "../server_hooks";
import { Updatable } from "../useUpdatableProps";
import { ToolTip } from "./ToolTip";
import { EyeOff, Eye } from "react-feather";
import { valOrLiteral, MaybeLabel } from "./MaybeLabel";
import { truthy } from "./StringWidget";
import { ariaInvalid } from "./CheckboxGroupWidget";

type Props = {
  id: string;
  label?: string;
  placeholder?: Updatable<string> | string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  autocomplete?: string;
  pattern?: string;
  patternHint?: string;
  debounce?: number;
  postfix?: "show_password" | string;
  prefix?: string;
} & RowProps;

export function PasswordWidget(props: Props) {
  const {
    id,
    label,
    placeholder,
    required,
    readOnly,
    disabled,
    autoFocus,
    autocomplete,
    pattern,
    patternHint,
    rowHasLabel,
    postfix,
    prefix,
  } = props;
  const [focus, setFocus] = useState(false);
  const [show, setShow] = useState(false);

  const { setVal, val, error, checkErrors, ref } = useValidatedVal<string>();

  useHandleMethodCall("focus", () => {
    ref.current?.focus();
    ref.current?.scrollIntoView();
  });

  // TODO: handle icons
  const post = postfix ? (
    postfix === "show_password" ? (
      <div
        className="input_postfix svg_icon"
        onClick={(e) => setShow((x) => !x)}
      >
        {show ? <EyeOff /> : <Eye />}
      </div>
    ) : (
      <div className="input_postfix">{postfix}</div>
    )
  ) : null;

  const pre = prefix ? <div className="input_prefix">{prefix}</div> : null;

  const hasConstraints = !!required || truthy(pattern);

  const input = (
    <input
      className={(post ? "has_postfix" : "") + (pre ? " has_prefix" : "")}
      aria-invalid={ariaInvalid(error, hasConstraints)}
      type={show ? "text" : "password"}
      ref={ref}
      value={val}
      required={required}
      readOnly={readOnly}
      disabled={disabled}
      autoFocus={autoFocus}
      autoComplete={autocomplete}
      pattern={pattern}
      data-patternhint={patternHint}
      placeholder={valOrLiteral(placeholder)}
      onFocus={(e) => setFocus(true)}
      onBlur={(e) => {
        checkErrors();
        setFocus(false);
      }}
      onChange={(e) => setVal(e.target.value, props.debounce)}
    />
  );

  return (
    <label>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <ToolTip message={focus && !!error ? error : undefined}>
        {post || pre ? (
          <div className="addon_container">
            {pre}
            {input}
            {post}
          </div>
        ) : (
          input
        )}
      </ToolTip>
    </label>
  );
}
